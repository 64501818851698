<template>
  <div class="section-two">
    <div class="title my-5">
      <div>خدماتنا تلبي كافة الاحتياجات !</div>
      <div>تقدم الأكاديمية دورات تعليمية وتدريبية متكاملة لجميع الراغبين في التعلم</div>
    </div>
    <div class="cards-container">
      <div class="card-2">
        <img src="@/assets/images/section2img1.png" alt="Study Abroad" />
        <div class="t1">تعليم الإنجليزية</div>
        <div class="t2">( الدراسة في الخارج )</div>
        <div class="t3">تعلم الإنجليزية في موطنها مع دعمنا لاختيار أفضل البرامج والمدارس المعترف بها!</div>
        <MainButton @click="$emit('scroll-to-packages')">قائمة الأسعار</MainButton>
      </div>
      <div class="card-2">
        <img src="@/assets/images/section2img2.png" alt="Kids Course" />
        <div class="t1">تأسيس الإنجليزية من (5-18) سنه</div>
        <div class="t2">( دورات للصغار )</div>
        <div class="t3">منهج المعايشة ينمي مهارات الإنجليزية للصغار والشباب عبر التفاعل اليومي والدعم المستمر للنجاح الشخصي والمهني.</div>
        <MainButton @click="$emit('scroll-to-packages')">قائمة الأسعار</MainButton>
      </div>
      <div class="card-2">
        <img src="@/assets/images/section2img3.png" alt="Adults Course" />
        <div class="t1">تعليم الإنجليزية</div>
        <div class="t2">( دورات للكبار )</div>
        <div class="t3">تحسين لغتك الإنجليزية وزيادة نجاحك بعملك عشر مرات من خلال التعلم عبر الإنترنت مع مدربين ذوي خبرة!</div>
        <MainButton @click="$emit('scroll-to-packages')">قائمة الأسعار</MainButton>
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from '../Elements/MainButton.vue';

export default {
  name: 'ViewTwo',
  components: {
    MainButton,
  },
}
</script>

<style src="@/assets/styles/ar/ViewTwo.css" scoped></style>
