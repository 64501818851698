<template>
  <div class="shopping-cart-en">
    <main class="cart-main-en">
      <div class="cart-info-en">
        <h1>Shopping Cart</h1>
      </div>
    </main>
    <div class="cart-page-en">
      <div v-if="cartItems.length === 0" class="empty-cart-en">
        <img src="@/assets/images/cart.png" alt="Empty Cart Image" />
        <h2>Your cart is empty</h2>
        <p>Start your educational journey now by enrolling in our courses and enjoy a unique learning experience!</p>
        <button class="back-to-courses-en" @click="goToCourses">Back to Courses</button>
      </div>
      <div v-else class="cart-content-en">
        <div class="cart-container-en cart-container-1-en">
          <div class="order-section-en">
            <table class="order-table-en">
              <tr>
                <th>Course</th>
                <th>Quantity</th>
                <th>Price</th>
                <th></th>
              </tr>
              <tr class="order-item-en" v-for="(item, index) in cartItems" :key="index">
                <td class="course-en">{{ item.course_package.name }}</td>
                <td class="quantity-en">
                  <button @click="decreaseQuantity(item.id)">-</button>
                  <label class="p-4">{{ item.quantity }}</label>
                  <input type="hidden" v-model="item.quantity" readonly/>
                  <button @click="increaseQuantity(item.id)">+</button>
                </td>
                <td class="price-en">{{ item.single_price }} {{ cartSummary.currency }}</td>
                <td>
                  <button class="remove-button-en" @click="removeItem(item.id)">
                    <svg aria-hidden="true" class="svg-inline--fa fa-xmark" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg="">
                      <path fill="currentColor" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
                    </svg>
                  </button>
                </td>
              </tr>
            </table>
            <table class="order-table-mobile-en" v-for="(item, index) in cartItems" :key="index">
              <tr>
                <td>Course</td>
                <td class="course-en">{{ item.course_package.name }}</td>
              </tr>
              <tr class="order-item-en">
                <td>Quantity</td>
                <td class="quantity-en">
                  <button @click="decreaseQuantity(item.id)">-</button>
                  <label class="p-4">{{ item.quantity }}</label>
                  <input type="hidden" v-model="item.quantity" readonly/>
                  <button @click="increaseQuantity(item.id)">+</button>
                </td>
              </tr>
              <tr class="price-row-en">
                <td>Price</td>
                <td class="price-en">{{ item.single_price }} {{ cartSummary.currency }}</td>
              </tr>
              <tr class="remove-row-en">
                <td></td>
                <td colspan="2">
                  <button class="remove-button-en" @click="removeItem(item.id)">
                    <svg aria-hidden="true" class="svg-inline--fa fa-xmark" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg="">
                      <path fill="currentColor" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
                    </svg>
                  </button>
                </td>
              </tr>
            </table>
            <button class="buy-another-course-en" @click="goToCourses">
              ←
              Buy Another Course 
            </button>
            <div v-if="orderUpdated" class="order-update-message-en">
              Cart updated.
            </div>
          </div>
        </div>
        <div class="cart-container-en cart-container-2-en">
          <div v-if="successMessage" class="success-message">
            {{ successMessage }}
          </div>
          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
          <div class="coupon-section-en">
            <label for="coupon-code-en">Have a discount code?</label>
            <div class="coupon-input-en">
              <input type="text" id="coupon-code-en" placeholder="Coupon code" v-model="couponCode"/>
              <button v-if="!cartSummary.coupon_code" @click="applyCoupon" class="apply-button-en">Apply</button>
              <button v-if="cartSummary.coupon_code" @click="removeCoupon" class="remove-coupon-button">Remove Coupon</button>
            </div>
          </div>
          <table class="price-table-en">
            <tr>
              <td v-if="cartSummary.total_price_discount == 0">Final Price</td>
              <td v-else>Initial Price</td>
              <td>{{ cartSummary.total_price }} {{ cartSummary.currency }}</td>
            </tr>
            <tr v-if="cartSummary.family_or_friend_discount > 0">
              <td>Family/Friends Discount</td>
              <td>
                <div class="remove-coupon-section">
                    - {{ cartSummary.family_or_friend_discount }} {{ cartSummary.currency }}
                </div>
              </td>
            </tr>
            <tr v-if="cartSummary.coupon_code">
              <td>Discount</td>
              <td>
                <div class="remove-coupon-section">
                  - {{ cartSummary.discount }} {{ cartSummary.currency }}
                </div>
              </td>
            </tr>
            <tr v-if="cartSummary.total_price_discount != 0">
              <td>Total Price</td>
              <td>{{ cartSummary.total_price_discount }} {{ cartSummary.currency }}</td>
            </tr>
          </table>
          <button class="confirm-button-en" @click="showAuthModal">Confirm Order</button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showModal" class="modal-en">
    <div class="modal-content-en">
      <span class="close-en" @click="closeModal">&times;</span>
      <form @submit.prevent="goToCheckout">
        <div v-if="validationErrorMessage" class="validation-container-en">
          <p id="validationError-en" class="error-en" v-html="validationErrorMessage"></p>
          <span class="error-en">,</span> <a v-if="link" href="https://front.monglish.academy/login" class="text-linkkk-en">Login</a> <span class="error-en">or enter another email</span>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="firstname">First Name</label>
          </div>
          <div class="md:w-2/3">
            <input class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" type="text" id="firstname" v-model="firstname" />
            <p v-if="errors.firstname" class="error-en">{{ errors.firstname }}</p>
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="lastname">Last Name</label>
          </div>
          <div class="md:w-2/3">
            <input class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" type="text" id="lastname" v-model="lastname" />
            <p v-if="errors.lastname" class="error-en">{{ errors.lastname }}</p>
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="country">Country / Region</label>
          </div>
          <div class="md:w-2/3 relative">
            <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="country" v-model="country">
              <option value="Afghanistan">Afghanistan</option>
              <option value="Åland Islands">Åland Islands</option>
              <option value="Albania">Albania</option>
              <option value="Algeria">Algeria</option>
              <option value="American Samoa">American Samoa</option>
              <option value="Andorra">Andorra</option>
              <option value="Angola">Angola</option>
              <option value="Anguilla">Anguilla</option>
              <option value="Antarctica">Antarctica</option>
              <option value="Antigua and Barbuda">Antigua and Barbuda</option>
              <option value="Argentina">Argentina</option>
              <option value="Armenia">Armenia</option>
              <option value="Aruba">Aruba</option>
              <option value="Australia">Australia</option>
              <option value="Austria">Austria</option>
              <option value="Azerbaijan">Azerbaijan</option>
              <option value="Bahamas">Bahamas</option>
              <option value="Bahrain">Bahrain</option>
              <option value="Bangladesh">Bangladesh</option>
              <option value="Barbados">Barbados</option>
              <option value="Belarus">Belarus</option>
              <option value="Belgium">Belgium</option>
              <option value="Belize">Belize</option>
              <option value="Benin">Benin</option>
              <option value="Bermuda">Bermuda</option>
              <option value="Bhutan">Bhutan</option>
              <option value="Bolivia">Bolivia</option>
              <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
              <option value="Botswana">Botswana</option>
              <option value="Bouvet Island">Bouvet Island</option>
              <option value="Brazil">Brazil</option>
              <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
              <option value="Brunei Darussalam">Brunei Darussalam</option>
              <option value="Bulgaria">Bulgaria</option>
              <option value="Burkina Faso">Burkina Faso</option>
              <option value="Burundi">Burundi</option>
              <option value="Cambodia">Cambodia</option>
              <option value="Cameroon">Cameroon</option>
              <option value="Canada">Canada</option>
              <option value="Cape Verde">Cape Verde</option>
              <option value="Cayman Islands">Cayman Islands</option>
              <option value="Central African Republic">Central African Republic</option>
              <option value="Chad">Chad</option>
              <option value="Chile">Chile</option>
              <option value="China">China</option>
              <option value="Christmas Island">Christmas Island</option>
              <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
              <option value="Colombia">Colombia</option>
              <option value="Comoros">Comoros</option>
              <option value="Congo">Congo</option>
              <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
              <option value="Cook Islands">Cook Islands</option>
              <option value="Costa Rica">Costa Rica</option>
              <option value="Cote D'ivoire">Cote D'ivoire</option>
              <option value="Croatia">Croatia</option>
              <option value="Cuba">Cuba</option>
              <option value="Cyprus">Cyprus</option>
              <option value="Czech Republic">Czech Republic</option>
              <option value="Denmark">Denmark</option>
              <option value="Djibouti">Djibouti</option>
              <option value="Dominica">Dominica</option>
              <option value="Dominican Republic">Dominican Republic</option>
              <option value="Ecuador">Ecuador</option>
              <option value="Egypt">Egypt</option>
              <option value="El Salvador">El Salvador</option>
              <option value="Equatorial Guinea">Equatorial Guinea</option>
              <option value="Eritrea">Eritrea</option>
              <option value="Estonia">Estonia</option>
              <option value="Ethiopia">Ethiopia</option>
              <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
              <option value="Faroe Islands">Faroe Islands</option>
              <option value="Fiji">Fiji</option>
              <option value="Finland">Finland</option>
              <option value="France">France</option>
              <option value="French Guiana">French Guiana</option>
              <option value="French Polynesia">French Polynesia</option>
              <option value="French Southern Territories">French Southern Territories</option>
              <option value="Gabon">Gabon</option>
              <option value="Gambia">Gambia</option>
              <option value="Georgia">Georgia</option>
              <option value="Germany">Germany</option>
              <option value="Ghana">Ghana</option>
              <option value="Gibraltar">Gibraltar</option>
              <option value="Greece">Greece</option>
              <option value="Greenland">Greenland</option>
              <option value="Grenada">Grenada</option>
              <option value="Guadeloupe">Guadeloupe</option>
              <option value="Guam">Guam</option>
              <option value="Guatemala">Guatemala</option>
              <option value="Guernsey">Guernsey</option>
              <option value="Guinea">Guinea</option>
              <option value="Guinea-bissau">Guinea-bissau</option>
              <option value="Guyana">Guyana</option>
              <option value="Haiti">Haiti</option>
              <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
              <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
              <option value="Honduras">Honduras</option>
              <option value="Hong Kong">Hong Kong</option>
              <option value="Hungary">Hungary</option>
              <option value="Iceland">Iceland</option>
              <option value="India">India</option>
              <option value="Indonesia">Indonesia</option>
              <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
              <option value="Iraq">Iraq</option>
              <option value="Ireland">Ireland</option>
              <option value="Isle of Man">Isle of Man</option>
              <option value="Israel">Israel</option>
              <option value="Italy">Italy</option>
              <option value="Jamaica">Jamaica</option>
              <option value="Japan">Japan</option>
              <option value="Jersey">Jersey</option>
              <option value="Jordan">Jordan</option>
              <option value="Kazakhstan">Kazakhstan</option>
              <option value="Kenya">Kenya</option>
              <option value="Kiribati">Kiribati</option>
              <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
              <option value="Korea, Republic of">Korea, Republic of</option>
              <option value="Kuwait">Kuwait</option>
              <option value="Kyrgyzstan">Kyrgyzstan</option>
              <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
              <option value="Latvia">Latvia</option>
              <option value="Lebanon">Lebanon</option>
              <option value="Lesotho">Lesotho</option>
              <option value="Liberia">Liberia</option>
              <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
              <option value="Liechtenstein">Liechtenstein</option>
              <option value="Lithuania">Lithuania</option>
              <option value="Luxembourg">Luxembourg</option>
              <option value="Macao">Macao</option>
              <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
              <option value="Madagascar">Madagascar</option>
              <option value="Malawi">Malawi</option>
              <option value="Malaysia">Malaysia</option>
              <option value="Maldives">Maldives</option>
              <option value="Mali">Mali</option>
              <option value="Malta">Malta</option>
              <option value="Marshall Islands">Marshall Islands</option>
              <option value="Martinique">Martinique</option>
              <option value="Mauritania">Mauritania</option>
              <option value="Mauritius">Mauritius</option>
              <option value="Mayotte">Mayotte</option>
              <option value="Mexico">Mexico</option>
              <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
              <option value="Moldova, Republic of">Moldova, Republic of</option>
              <option value="Monaco">Monaco</option>
              <option value="Mongolia">Mongolia</option>
              <option value="Montenegro">Montenegro</option>
              <option value="Montserrat">Montserrat</option>
              <option value="Morocco">Morocco</option>
              <option value="Mozambique">Mozambique</option>
              <option value="Myanmar">Myanmar</option>
              <option value="Namibia">Namibia</option>
              <option value="Nauru">Nauru</option>
              <option value="Nepal">Nepal</option>
              <option value="Netherlands">Netherlands</option>
              <option value="Netherlands Antilles">Netherlands Antilles</option>
              <option value="New Caledonia">New Caledonia</option>
              <option value="New Zealand">New Zealand</option>
              <option value="Nicaragua">Nicaragua</option>
              <option value="Niger">Niger</option>
              <option value="Nigeria">Nigeria</option>
              <option value="Niue">Niue</option>
              <option value="Norfolk Island">Norfolk Island</option>
              <option value="Northern Mariana Islands">Northern Mariana Islands</option>
              <option value="Norway">Norway</option>
              <option value="Oman">Oman</option>
              <option value="Pakistan">Pakistan</option>
              <option value="Palau">Palau</option>
              <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
              <option value="Panama">Panama</option>
              <option value="Papua New Guinea">Papua New Guinea</option>
              <option value="Paraguay">Paraguay</option>
              <option value="Peru">Peru</option>
              <option value="Philippines">Philippines</option>
              <option value="Pitcairn">Pitcairn</option>
              <option value="Poland">Poland</option>
              <option value="Portugal">Portugal</option>
              <option value="Puerto Rico">Puerto Rico</option>
              <option value="Qatar">Qatar</option>
              <option value="Reunion">Reunion</option>
              <option value="Romania">Romania</option>
              <option value="Russian Federation">Russian Federation</option>
              <option value="Rwanda">Rwanda</option>
              <option value="Saint Helena">Saint Helena</option>
              <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
              <option value="Saint Lucia">Saint Lucia</option>
              <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
              <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
              <option value="Samoa">Samoa</option>
              <option value="San Marino">San Marino</option>
              <option value="Sao Tome and Principe">Sao Tome and Principe</option>
              <option value="Saudi Arabia">Saudi Arabia</option>
              <option value="Senegal">Senegal</option>
              <option value="Serbia">Serbia</option>
              <option value="Seychelles">Seychelles</option>
              <option value="Sierra Leone">Sierra Leone</option>
              <option value="Singapore">Singapore</option>
              <option value="Slovakia">Slovakia</option>
              <option value="Slovenia">Slovenia</option>
              <option value="Solomon Islands">Solomon Islands</option>
              <option value="Somalia">Somalia</option>
              <option value="South Africa">South Africa</option>
              <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
              <option value="Spain">Spain</option>
              <option value="Sri Lanka">Sri Lanka</option>
              <option value="Sudan">Sudan</option>
              <option value="Suriname">Suriname</option>
              <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
              <option value="Swaziland">Swaziland</option>
              <option value="Sweden">Sweden</option>
              <option value="Switzerland">Switzerland</option>
              <option value="Syrian Arab Republic">Syrian Arab Republic</option>
              <option value="Taiwan">Taiwan</option>
              <option value="Tajikistan">Tajikistan</option>
              <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
              <option value="Thailand">Thailand</option>
              <option value="Timor-leste">Timor-leste</option>
              <option value="Togo">Togo</option>
              <option value="Tokelau">Tokelau</option>
              <option value="Tonga">Tonga</option>
              <option value="Trinidad and Tobago">Trinidad and Tobago</option>
              <option value="Tunisia">Tunisia</option>
              <option value="Turkey">Turkey</option>
              <option value="Turkmenistan">Turkmenistan</option>
              <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
              <option value="Tuvalu">Tuvalu</option>
              <option value="Uganda">Uganda</option>
              <option value="Ukraine">Ukraine</option>
              <option value="United Arab Emirates">United Arab Emirates</option>
              <option value="United Kingdom">United Kingdom</option>
              <option value="United States">United States</option>
              <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
              <option value="Uruguay">Uruguay</option>
              <option value="Uzbekistan">Uzbekistan</option>
              <option value="Vanuatu">Vanuatu</option>
              <option value="Venezuela">Venezuela</option>
              <option value="Viet Nam">Viet Nam</option>
              <option value="Virgin Islands, British">Virgin Islands, British</option>
              <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
              <option value="Wallis and Futuna">Wallis and Futuna</option>
              <option value="Western Sahara">Western Sahara</option>
              <option value="Yemen">Yemen</option>
              <option value="Zambia">Zambia</option>
              <option value="Zimbabwe">Zimbabwe</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
            </div>
            <p v-if="errors.country" class="error-en">{{ errors.country }}</p>
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="phone">Phone Number</label>
          </div>
          <div class="md:w-2/3">
            <input class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" type="text" id="phone" v-model="phone" />
            <p v-if="errors.phone" class="error-en">{{ errors.phone }}</p>
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="email">Email Address</label>
          </div>
          <div class="md:w-2/3">
            <input class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" type="email" id="email" v-model="email" />
            <p v-if="errors.email" class="error-en">{{ errors.email }}</p>
          </div>
        </div>
        <button type="submit" class="checkout-button-en">Complete Order</button>
      </form>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    data() {
      return {
        orderUpdated: false,
        showModal:false,
        cartItems: [], // Initialize cartItems as an empty array
        cartSummary: {
          total_price: 0,
          total_price_discount: 0,
          family_or_friend_discount: 0,
          currency: '',
          total_items_count: 0,
          coupon_code: null, // Ensure coupon_code is defined
          discount: 0, // Add discount to cartSummary
        },
        firstname: '',
        lastname: '',
        country: '',
        phone: '',
        email: '',
        couponCode: '',      // Holds the coupon code input
        successMessage: '',  // Holds success message
        errors: {}, // To store form errors
        errorMessage: '',    // Holds error message
        validationErrorMessage: '',
        link: false,
      };
    },
    methods: {
      async fetchCartItems() {
        let url = sessionStorage.getItem('userInfo') ? '/api/user/cart' : '/api/session/cart';

        const userInfo = sessionStorage.getItem('userInfo');
        let headers = {};
        if (userInfo) {
          try {
            const parsedUserInfo = JSON.parse(userInfo);
            const token = parsedUserInfo.token;

            if (token) {
              headers['Authorization'] = `Bearer ${token}`;
            }
          } catch (error) {
            console.error('Error parsing userInfo from sessionStorage:', error);
          }
        }

        axios.get(url, { headers })
          .then(response => {
            // Store cart items in data
            this.cartItems = response.data.data.items || [];
            this.orderUpdated = false; // Reset update status
            this.cartSummary = response.data.data;
            if(this.cartSummary.coupon_code){
              this.couponCode = this.cartSummary.coupon_code['code'];
            }
          })
          .catch(error => {
            if (error.response && error.response.status === 404) {
            //   // If the cart is not found, treat it as empty
              this.cartItems = [];
              this.cartSummary = {};
              console.warn('Cart is empty or was deleted:', error.response.data);
            } else {
              console.error('Error fetching cart items:', error.response ? error.response.data : error.message);
            }
          });
      },
      async increaseQuantity(itemId) {
        let url = sessionStorage.getItem('userInfo') ? `/api/user/cart/increase/${itemId}` : `/api/session/cart/increase/${itemId}`;

        const userInfo = sessionStorage.getItem('userInfo');
        let headers = {};
        if (userInfo) {
          try {
            const parsedUserInfo = JSON.parse(userInfo);
            const token = parsedUserInfo.token;

            if (token) {
              headers['Authorization'] = `Bearer ${token}`;
            }
          } catch (error) {
            console.error('Error parsing userInfo from sessionStorage:', error);
          }
        }

        axios.get(url, { headers })
          .then(() => this.fetchCartItems()) // Refresh cart items
          .catch(error => {
            console.error('Error increasing item quantity:', error);
          });
      },
      async decreaseQuantity(itemId) {
        let url = sessionStorage.getItem('userInfo') ? `/api/user/cart/decrease/${itemId}` : `/api/session/cart/decrease/${itemId}`;

        const userInfo = sessionStorage.getItem('userInfo');
        let headers = {};
        if (userInfo) {
          try {
            const parsedUserInfo = JSON.parse(userInfo);
            const token = parsedUserInfo.token;

            if (token) {
              headers['Authorization'] = `Bearer ${token}`;
            }
          } catch (error) {
            console.error('Error parsing userInfo from sessionStorage:', error);
          }
        }

        axios.get(url, { headers })
          .then(() => this.fetchCartItems()) // Refresh cart items
          .catch(error => {
            console.error('Error decreasing item quantity:', error);
          });
      },
      async removeItem(itemId) {
        let url = sessionStorage.getItem('userInfo') ? `/api/user/cart/remove/${itemId}` : `/api/session/cart/remove/${itemId}`;

        const userInfo = sessionStorage.getItem('userInfo');
        let headers = {};
        if (userInfo) {
          try {
            const parsedUserInfo = JSON.parse(userInfo);
            const token = parsedUserInfo.token;

            if (token) {
              headers['Authorization'] = `Bearer ${token}`;
            }
          } catch (error) {
            console.error('Error parsing userInfo from sessionStorage:', error);
          }
        }

        axios.get(url, { headers })
        .then(() => this.fetchCartItems())
        .catch(error => {
          this.errorMessage = 'Error removing item.';
          console.error('Error removing item:', error);
        });
      },
      async applyCoupon() {
        // Reset messages
        this.successMessage = '';
        this.errorMessage = '';

        if (!this.couponCode.trim()) {
          this.errorMessage = 'Please enter a valid coupon code.';
          return;
        }
        try {
          let url = sessionStorage.getItem('userInfo') ? '/api/user/cart/apply-coupon' : '/api/session/cart/apply-coupon';

          const userInfo = sessionStorage.getItem('userInfo');
          let headers = {};
          if (userInfo) {
            try {
              const parsedUserInfo = JSON.parse(userInfo);
              const token = parsedUserInfo.token;

              if (token) {
                headers['Authorization'] = `Bearer ${token}`;
              }
            } catch (error) {
              console.error('Error parsing userInfo from sessionStorage:', error);
            }
          }

          const response = await axios.post(url, { coupon_code: this.couponCode }, { headers });

          if (response.data.success) {
              this.cartSummary = response.data.data.cartSummary;
              this.successMessage = response.data.message;
              this.couponCode = this.cartSummary.coupon_code; // Clear the input field on success
            } else {
              this.errorMessage = response.data.message;
            }
        } catch (error) {
          this.errorMessage = 'Failed to apply coupon. Please try again.';
        }
      },
      async removeCoupon() {
        // Reset messages
        this.successMessage = '';
        this.errorMessage = '';

        try {
          let url = sessionStorage.getItem('userInfo') ? '/api/user/cart/remove-coupon' : '/api/session/cart/remove-coupon';

          const userInfo = sessionStorage.getItem('userInfo');
          let headers = {};
          if (userInfo) {
            try {
              const parsedUserInfo = JSON.parse(userInfo);
              const token = parsedUserInfo.token;

              if (token) {
                headers['Authorization'] = `Bearer ${token}`;
              }
            } catch (error) {
              console.error('Error parsing userInfo from sessionStorage:', error);
            }
          }

          const response = await axios.post(url, {}, { headers });
          console.log(response.data);
          if (response.data.success) {
            this.cartSummary = response.data.data.cartSummary || {};
            this.successMessage = response.data.message;
            this.couponCode = ''; // Clear the input field when coupon is removed
          } else {
            this.errorMessage = response.data.message;
          }
        } catch (error) {
          this.errorMessage = 'Failed to remove coupon. Please try again.';
        }
      },
      goToCourses() {
        this.$router.push('/en/packages');
      },
      validateForm() {
        this.errors = {};

        if (!this.firstname) {
          this.errors.firstname = 'Firstname is required';
        }

        if (!this.lastname) {
          this.errors.lastname = 'Lastname is required';
        }

        if (!this.country) {
          this.errors.country = 'Country / Region is required';
        }

        if (!this.phone) {
          this.errors.phone = 'Phone is required';
        }

        if (!this.email) {
          this.errors.email = 'Email address is required';
        } else if (!this.isValidEmail(this.email)) {
          this.errors.email = 'Email address is invalid';
        }
      },
      isValidEmail(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
      },
      goToCheckout() {
        
        let url = sessionStorage.getItem('userInfo')
          ? '/api/user/checkout'
          : '/api/session/checkout';

        const userInfo = sessionStorage.getItem('userInfo');
        let headers = {};
        let formData = {};
        if (userInfo) {
          try {
            const parsedUserInfo = JSON.parse(userInfo);
            const token = parsedUserInfo.token;

            if (token) {
              headers['Authorization'] = `Bearer ${token}`;
            }
          } catch (error) {
            console.error('Error parsing userInfo from sessionStorage:', error);
          }
        } else{
          formData = {
            firstname: this.firstname,
            lastname: this.lastname,
            country: this.country,
            phone: this.phone,
            email: this.email,
          };
          this.validateForm();
        }

        // If there are no errors, submit the form
        if (Object.keys(this.errors).length === 0) {

          axios.post(url, formData, { headers })
          .then((response) => {
            // Handle successful form submission
            console.log('Order checkout successfully:', response.data);
            if (response.data.success) {
              // Redirect to the URL in the response data
              window.location.href = response.data.data;
            } else {
              console.error('Error:', response.data.message);
              // Optionally, display an error message to the user
            }
          })
          .catch((error) => {
            // Handle errors
            console.error('Error submitting form:', error.response.data);
            this.validationErrorMessage = error.response.data.data.error || 'حدث خطأ أثناء إرسال النموذج. حاول مرة أخرى.';
            if((error.response.data.message).includes('User Exists')){
              this.link = true;
            }
          });
        }
      },
      showAuthModal() {
        if (sessionStorage.getItem('userInfo')) {
          this.goToCheckout();
        } else {
          this.showModal = true;
        }
      },
      closeModal() {
        this.showModal = false;
      },
    },
    computed: {
      totalPrice() {
        return this.cartItems.reduce((total, item) => total + item.single_price * item.quantity, 0);
      }
    },
    mounted() {
      this.fetchCartItems(); // Fetch cart items when component is mounted
    }
  };
</script>

<style scoped>
  .shopping-cart-en {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('@/assets/images/cart_bg.png');
    background-size: cover;
    background-position: center;
    direction: ltr;
    margin-top: -85px;
  }

  .cart-main-en {
    display: flex;
    width: 100%;
  }

  .cart-info-en {
    text-align: left;
    opacity: 0.9;
    margin-bottom: 5%;
    margin-left: 5%;
  }

  .cart-info-en h1 {
    font-size: 3rem;
    color: #fff;
    font-family: 'DIN Next LT Arabic';
    font-weight: 500;
    margin-top: 200px;
  }

  .cart-page-en {
    display: flex;
    font-family: 'DIN Next LT Arabic';
    font-weight: 500;
    justify-content: center;
    width: 100%;
    background-color: white;
  }

  .empty-cart-en {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    gap: 20px;
  }

  .empty-cart-en img {
    width: 20vw;
  }

  .empty-cart-en h2 {
    font-size: 2rem;
    color: #333;
    line-height: 80%;
  }

  .empty-cart-en p {
    font-size: 1.2rem;
    color: #666;
    line-height: 100%;
  }

  .back-to-courses-en {
    background: linear-gradient(45deg, #ff9442, #ff6f00);
    color: white;
    border: none;
    border-radius: 15px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 22px;
    margin-bottom: 20px;
    font-family: 'DIN Next LT Arabic';
    font-weight: 500;
  }

  /* cart items */
  .cart-content-en {
    display: flex;
    width: 100%;
  }

  .cart-container-en {
    padding: 30px;
    border-radius: 15px;
    width: 100%;
    margin-bottom: 20px;
  }

  .cart-container-1-en {
    max-width: 70%;
    position: relative;
    right: 0;
    top: -90px;
  }

  .cart-container-2-en {
    max-width: 30%;
    position: relative;
    right: 0;
    top: -50px;
    background: white;
  }

  .coupon-section-en {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .coupon-input-en {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  #coupon-code-en {
    padding: 15px;
    border-radius: 15px;
    margin-right: 10px;
    border: 1px solid #ccc;
    width: 60%;
  }

  .remove-coupon-section-en{
    color:#ff1919;
  }

  .apply-button-en, .remove-coupon-button-en {
    background: linear-gradient(45deg, #ff9442, #ff6f00);
    color: white;
    border: none;
    border-radius: 15px;
    padding: 10px;
    cursor: pointer;
    font-size: 18px;
    font-family: 'DIN Next LT Arabic';
    font-weight: 500;
    width: 40%;
  }

  .remove-coupon-button-en{
    background: linear-gradient(45deg, #fd7878, #fa5353);
  }

  .price-table-en {
    width: 100%;
    margin-bottom: 20px;
    border-collapse: collapse;
    font-weight: bold;
    font-family: 'DIN Next LT Arabic';
    font-size: 20px;
  }

  .price-table-en td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
    font-size: 18px;
    direction: rtl;
  }

  .confirm-button-en {
    background: linear-gradient(45deg, #ff9442, #ff6f00);
    color: white;
    border: none;
    border-radius: 15px;
    padding: 15px;
    width: 100%;
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .order-section-en {
    padding: 15px;
  }

  .order-table-en {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-family: 'DIN Next LT Arabic';
    font-weight: 500;
  }

  .order-table-mobile-en {
    display: none;
  }

  .order-table-en th,
  .order-table-en td {
    border: 1px solid #ddd;
    text-align: center;
    padding: 10px;
    direction: rtl;
    border-left: 0;
    border-right: 0;
  }

  .order-table-en th{
    border-top: 0;
    padding-bottom: 20px;
    color: white;
  }

  .order-item-en {
    background-color: white;
  }

  .price-en {
    color: #f47920;
    font-size: 26px;
    font-weight: 600;
  }

  .quantity-en {
    align-items: center;
    justify-content: center;
    direction: ltr !important;
  }

  .quantity-en input {
    width: 40px;
    text-align: center;
    margin: 0 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
  }

  .quantity-en button {
    background-color: #fff;
    border: 1px solid #ccc;
    color: #808b94;
    padding: 5px;
    font-size: 18px;
    cursor: pointer;
  }

  .course-en {
    font-size: 26px;
    font-weight: bold;
    transition: color 0.3s;
  }

  .course-en:hover {
    color: #f47920;
  }

  .remove-button-en {
    background-color: transparent;
    border: 1px solid #F47D21;
    border-radius: 50%;
    color: #f47920;
    cursor: pointer;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, color 0.3s;
  }

  .remove-button-en:hover {
    background-color: #f47920;
    color: white;
  }

  .buy-another-course-en {
    background-color: #165e84;
    color: #fff;
    padding: 16px 20px 16px 20px;
    margin: 0px 0px 0px 0px;
    font-family: 'DIN Next LT Arabic-n', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: center;
  }
  /* end cart item */

  .order-update-message-en {
    background-color: #d4edda;
    color: #155724;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
  }

  .success-message-en {
    color: green;
    margin-top: 1em;
  }

  .error-message-en {
    color: red;
    margin-top: 1em;
  }

  .validation-container-en{
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .error-en {
    color: red;
  }

  .text-linkkk-en{
    color:#0f73a8 !important;
    text-decoration: underline !important;
  }

  .text-linkkk-en:hover{
    color:#034b72 !important;
    text-decoration: none !important;
  }

  .checkout-form-group-en {
    margin-bottom: 18px;
    text-align: left;
  }
  .checkout-form-group-en label {
    margin-bottom: 100px;
    margin-right:10px;
    font-size: 18px;
    color: #333;
  }
  .checkout-form-group-en input, .checkout-form-group-en select {
    padding: 16px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 50%;
    text-align: left;
  }

  .checkout-button-en {
    background: linear-gradient(45deg, #ff9442, #ff6f00);
    color: #fff;
    padding: 16px 24px;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
    margin-top: 20px;
    transition: background-color 0.3s ease;
    width: 100%;
  }

  .checkout-button-en:hover {
    background-color: #ff9442;
  }

  .checkout-form-en {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 900px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  .personal-section-en {
    width: 100%;
  }

  .modal-en {
    display: block;
    position: fixed;
    z-index: 2000;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
  }

  .modal-content-en {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 25%;
  }

  .modal-content-en form{
    margin-top: 50px;
    direction: ltr;
  }

  .close-en {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close-en:hover,
  .close-en:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  @media (min-width: 320px) and (max-width: 479px) {
    .cart-content-en{
      flex-direction: column;
    }
    .cart-container-1-en {
      max-width: 100%;
      position: relative;
      left: 0;
      top: -40px;
      padding: 10px;
    }

    .order-table-en{
      display: none;
    }

    .order-table-mobile-en {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
      font-family: 'DIN Next LT Arabic';
      font-weight: 500;
    }

    .order-table-mobile-en tr {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ddd;
      margin: 10px;
    }

    .remove-row-en {
      border-bottom: 0 !important;
      flex-direction: row;
    }

    .price-row td{
      color: #f47920;
      font-size: 26px;
      font-weight: 600;
    }

    .order-table-mobile-en td {  
      text-align: center;
      padding: 10px;
      border-left: 0;
      border-right: 0;
    }

    .order-item-en {
      background-color: white;
    }

    .price-en {
      color: #f47920;
      font-size: 26px;
      font-weight: 600;
    }

    .quantity-en {
      align-items: center;
      justify-content: center;
    }

    .quantity-en input {
      width: 40px;
      text-align: center;
      margin: 0 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 5px;
    }

    .quantity-en button {
      background-color: #fff;
      border: 1px solid #ccc;
      color: #808b94;
      padding: 5px;
      font-size: 18px;
      cursor: pointer;
    }

    .course-en {
      font-size: 26px;
      font-weight: bold;
      transition: color 0.3s;
    }

    .course-en:hover {
      color: #f47920;
    }

    .remove-button-en {
      background-color: transparent;
      border: 1px solid #F47D21;
      border-radius: 50%;
      color: #f47920;
      cursor: pointer;
      width: 25px;
      height: 25px;
      align-items: center;
      justify-content: center;
      transition: background-color 0.3s, color 0.3s;
    }

    .remove-button-en:hover {
      background-color: #f47920;
      color: white;
    }

    .cart-container-2-en {
      max-width: 100%;
    }

    .price-table-en td {
      direction: ltr;
    }
  }
</style>
