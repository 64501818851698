<template>
  <div class="packages">
    <div class="packages-title-n">
      <h3>Pricing List</h3>
      <h1>Smart Investment, Perfect Education</h1>
    </div>
    <!-- Filter Switch -->
    <div class="packages-filter-switch">
      <div class="label-out">
        <span>Kids</span>
        <span>Adults</span>
      </div>
      <label class="switch">
        <input type="checkbox" @change="toggleFilter" :checked="selectedFilter === 'adults'" />
        <span class="slider">
          <span class="label label-left">Adults</span>
          <span class="label label-right">Kids</span>
        </span>
      </label>
    </div>

    <div class="packages-menu-n" v-if="selectedFilter === 'kids'">
      <ul>
        <li @click="selectMenu('six-months')" :class="{ selected: selectedMenu === 'six-months' }">Six Months</li>
        <li @click="selectMenu('four-months')" :class="{ selected: selectedMenu === 'four-months' }">Four Months</li>
        <li @click="selectMenu('two-months')" :class="{ selected: selectedMenu === 'two-months' }">Two Months</li>
      </ul>
    </div>

    <!-- Kids Packages -->
    <div v-if="selectedFilter === 'kids'">
      <div class="packages-cards-en" v-if="selectedMenu === 'two-months'">
        <!-- Two Months Cards -->
        <div class="package-card-en">
          <div class="card-header-en">
            <h3>Basic <span>(2 Months)</span></h3>
          </div>
          <div class="card-body">
            <p>The basic package is the least expensive but includes some features of our courses, providing a distinguished educational experience.</p>
            <h2>{{ prices[1]?.price }}</h2>
            
            <h6>Enjoy the following:</h6>
            <ul>
              <li>Basic Classes: 16 sessions</li>
              <li>Reading Club: 2 sessions</li>
              <li>Conversation Club (daily)</li>
              <li>Debate Club (monthly)</li>
              <li>Daily Follow-up</li>
              <li>Monthly Reports</li>
            </ul>
            <button @click="addToCart(1)">Buy</button>
          </div>
        </div>

        <div class="package-card-en">
          <div class="card-header-en">
            <h3>Immersion <span>(2 Months)</span></h3>
            <p class="badgee"><span>Most <br><bold>Selling</bold></span></p>
          </div>
          <div class="card-body">
            <p>The “Immersion” package offers many features and is the usual choice for our customers, ensuring comfort and excellent services efficiently.</p>
            <h2>{{ prices[2]?.price }}</h2>
            
            <h6>Enjoy the following:</h6>
            <ul>
              <li>Basic Classes: 16 sessions</li>
              <li>Reading Club: 8 sessions (weekly)</li>
              <li>Cinema Club: 8 sessions</li>
              <li>Conversation Club (daily)</li>
              <li>Debate Club (monthly)</li>
              <li>Life Coach Club: 1 session (individual)</li>
              <li>Daily Follow-up</li>
              <li>Monthly Reports</li>
            </ul>
            <button @click="addToCart(2)">Buy</button>
          </div>
        </div>
        <div class="package-card-en">
          <div class="card-header-en">
            <h3>Immersion Plus <span>(2 Months)</span></h3>
            <p class="badgee"><span>Best <br><bold>Value</bold></span></p>
          </div>
          <div class="card-body">
            <p>“Immersion Plus” is your ideal package, offering maximum value for money with unique features and comprehensive service that ensures your comfort.</p>
            <h2>{{ prices[3]?.price }}</h2>
            
            <h6>Enjoy the following:</h6>
            <ul>
              <li>Basic Classes: 16 sessions</li>
              <li>Reading Club: 8 sessions (weekly)</li>
              <li>Cinema Club: 8 sessions</li>
              <li>Conversation Club (daily)</li>
              <li>Debate Club (monthly)</li>
              <li>Talent Club: (weekly)</li>
              <li>Art Club: (weekly)</li>
              <li>Parenting Club: 2 lectures (for parents)</li>
              <li>Phonics Club: (by teacher's request)</li>
              <li>Writing Club: (weekly)</li>
              <li>Life Coach Club: 2 sessions (individual)</li>
              <li>Daily Follow-up</li>
              <li>Monthly Reports</li>
            </ul>
            <button @click="addToCart(3)">Buy</button>
          </div>
        </div>
      </div>

      <div class="packages-cards-en" v-else-if="selectedMenu === 'four-months'">
        <!-- Four Months Cards -->
        <div class="package-card-en">
          <div class="card-header-en">
            <h3>Basic <span>(4 Months)</span></h3>
          </div>
          <div class="card-body">
            <p>The basic package is the least expensive but includes some features of our courses, providing a distinguished educational experience.</p>
            <h2>{{ prices[4]?.price }}</h2>
            
            <h6>Enjoy the following:</h6>
            <ul>
              <li>Basic Classes: 16 sessions</li>
              <li>Reading Club: 2 sessions</li>
              <li>Conversation Club (daily)</li>
              <li>Debate Club (monthly)</li>
              <li>Daily Follow-up</li>
              <li>Monthly Reports</li>
            </ul>
            <button @click="addToCart(4)">Buy</button>
          </div>
        </div>

        <div class="package-card-en">
          <div class="card-header-en">
            <h3>Immersion <span>(4 Months)</span></h3>
            <p class="badgee"><span>Most <br><bold>Selling</bold></span></p>
          </div>
          <div class="card-body">
            <p>The “Immersion” package offers many features and is the usual choice for our customers, ensuring comfort and excellent services efficiently.</p>
            <h2>{{ prices[5]?.price }}</h2>
            
            <h6>Enjoy the following:</h6>
            <ul>
              <li>Basic Classes: 16 sessions</li>
              <li>Reading Club: 8 sessions (weekly)</li>
              <li>Cinema Club: 8 sessions</li>
              <li>Conversation Club (daily)</li>
              <li>Debate Club (monthly)</li>
              <li>Life Coach Club: 1 session (individual)</li>
              <li>Daily Follow-up</li>
              <li>Monthly Reports</li>
            </ul>
            <button @click="addToCart(5)">Buy</button>
          </div>
        </div>

        <div class="package-card-en">
          <div class="card-header-en">
            <h3>Immersion Plus <span>(4 Months)</span></h3>
            <p class="badgee"><span>Best <br><bold>Value</bold></span></p>
          </div>
          <div class="card-body">
            <p>“Immersion Plus” is your ideal package, offering maximum value for money with unique features and comprehensive service that ensures your comfort.</p>
            <h2>{{ prices[6]?.price }}</h2>
            
            <h6>Enjoy the following:</h6>
            <ul>
              <li>Basic Classes: 16 sessions</li>
              <li>Reading Club: 8 sessions (weekly)</li>
              <li>Cinema Club: 8 sessions</li>
              <li>Conversation Club (daily)</li>
              <li>Debate Club (monthly)</li>
              <li>Talent Club: (weekly)</li>
              <li>Art Club: (weekly)</li>
              <li>Parenting Club: 2 lectures (for parents)</li>
              <li>Phonics Club: (by teacher's request)</li>
              <li>Writing Club: (weekly)</li>
              <li>Life Coach Club: 2 sessions (individual)</li>
              <li>Daily Follow-up</li>
              <li>Monthly Reports</li>
            </ul>
            <button @click="addToCart(6)">Buy</button>
          </div>
        </div>
      </div>

      <div class="packages-cards-en" v-else-if="selectedMenu === 'six-months'">
        <!-- Six Months Cards -->
        <div class="package-card-en">
          <div class="card-header-en">
            <h3>Immersion <span>(6 Months)</span></h3>
            <p class="badgee"><span>Most <br><bold>Selling</bold></span></p>
          </div>
          <div class="card-body">
            <p>The “Immersion” package offers many features and is the usual choice for our customers, ensuring comfort and excellent services efficiently.</p>
            <h2>{{ prices[7]?.price }}</h2>
            
            <h6>Enjoy the following:</h6>
            <ul>
              <li>Basic Classes: 16 sessions</li>
              <li>Reading Club: 8 sessions (weekly)</li>
              <li>Cinema Club: 8 sessions</li>
              <li>Conversation Club (daily)</li>
              <li>Debate Club (monthly)</li>
              <li>Life Coach Club: 1 session (individual)</li>
              <li>Daily Follow-up</li>
              <li>Monthly Reports</li>
            </ul>
            <button @click="addToCart(7)">Buy</button>
          </div>
        </div>

        <div class="package-card-en">
          <div class="card-header-en">
            <h3>Immersion Plus <span>(6 Months)</span></h3>
            <p class="badgee"><span>Best <br><bold>Value</bold></span></p>
          </div>
          <div class="card-body">
            <p>“Immersion Plus” is your ideal package, offering maximum value for money with unique features and comprehensive service that ensures your comfort.</p>
            <h2>{{ prices[8]?.price }}</h2>
            
            <h6>Enjoy the following:</h6>
            <ul>
              <li>Basic Classes: 16 sessions</li>
              <li>Reading Club: 8 sessions (weekly)</li>
              <li>Cinema Club: 8 sessions</li>
              <li>Conversation Club (daily)</li>
              <li>Debate Club (monthly)</li>
              <li>Talent Club: (weekly)</li>
              <li>Art Club: (weekly)</li>
              <li>Parenting Club: 2 lectures (for parents)</li>
              <li>Phonics Club: (by teacher's request)</li>
              <li>Writing Club: (weekly)</li>
              <li>Life Coach Club: 2 sessions (individual)</li>
              <li>Daily Follow-up</li>
              <li>Monthly Reports</li>
            </ul>
            <button @click="addToCart(8)">Buy</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Adults Packages (Only 6 Months) -->
    <div class="packages-cards" v-if="selectedFilter === 'adults'">
      <div class="packages-cards-en">
        <!-- Six Months Cards -->
        <div class="package-card-en">
          <div class="card-header-en">
            <h3>Immersion <span>(6 Months)</span></h3>
            <p class="badgee"><span>Most <br><bold>Selling</bold></span></p>
          </div>
          <div class="card-body">
            <p>The “Immersion” package offers many features and is the usual choice for our customers, ensuring comfort and excellent services efficiently.</p>
            <h2>{{ prices[9]?.price }}</h2>
            
            <h6>Enjoy the following:</h6>
            <ul>
              <li>Basic Classes: 16 sessions</li>
              <li>Reading Club: 8 sessions (weekly)</li>
              <li>Cinema Club: 8 sessions</li>
              <li>Conversation Club (daily)</li>
              <li>Debate Club (monthly)</li>
              <li>Life Coach Club: 1 session (individual)</li>
              <li>Daily Follow-up</li>
              <li>Monthly Reports</li>
            </ul>
            <button @click="addToCart(9)">Buy</button>
          </div>
        </div>

        <div class="package-card-en">
          <div class="card-header-en">
            <h3>Immersion Plus <span>(6 Months)</span></h3>
            <p class="badgee"><span>Best <br><bold>Value</bold></span></p>
          </div>
          <div class="card-body">
            <p>“Immersion Plus” is your ideal package, offering maximum value for money with unique features and comprehensive service that ensures your comfort.</p>
            <h2>{{ prices[10]?.price }}</h2>
            
            <h6>Enjoy the following:</h6>
            <ul>
              <li>Basic Classes: 16 sessions</li>
              <li>Reading Club: 8 sessions (weekly)</li>
              <li>Cinema Club: 8 sessions</li>
              <li>Conversation Club (daily)</li>
              <li>Debate Club (monthly)</li>
              <li>Talent Club: (weekly)</li>
              <li>Art Club: (weekly)</li>
              <li>Parenting Club: 2 lectures (for parents)</li>
              <li>Phonics Club: (by teacher's request)</li>
              <li>Writing Club: (weekly)</li>
              <li>Life Coach Club: 2 sessions (individual)</li>
              <li>Daily Follow-up</li>
              <li>Monthly Reports</li>
            </ul>
            <button @click="addToCart(10)">Buy</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    data() {
      return {
        selectedFilter: 'kids',
        selectedMenu: 'two-months',
        prices: {},
      };
    },
    methods: {
      toggleFilter(event) {
        this.selectedFilter = event.target.checked ? 'adults' : 'kids';
        if (this.selectedFilter === 'adults') {
          this.selectedMenu = 'six-months';
        } else {
          this.selectedMenu = 'two-months';
        }
      },
      selectMenu(menu) {
        this.selectedMenu = menu;
      },
      fetchPackagePrices() {
        let url = sessionStorage.getItem('userInfo') ? '/api/user/get-course-packages' : '/api/session/get-course-packages';

        // Retrieve and parse userInfo from sessionStorage
        const userInfo = sessionStorage.getItem('userInfo');
        let headers = {};
        if (userInfo) {
          try {
            const parsedUserInfo = JSON.parse(userInfo);
            const token = parsedUserInfo.token;

            if (token) {
              // Add the token to the request headers
              headers['Authorization'] = `Bearer ${token}`;
            }
          } catch (error) {
            console.error('Error parsing userInfo from sessionStorage:', error);
          }
        }

        axios.get(url, { headers })
        .then(response => {
          const data = response.data;

          this.prices = {};

          if (data.success) {
            const packages = data.data;
            packages.forEach((packageData) => {
              const packageId = packageData.id; // Use package ID from the data
              const packagePrice = packageData.packagePrice;
              const currency = packageData.currency_en;
              
              // Set the price in the prices object using the package ID
              this.prices[packageId] = {
                price: `${packagePrice} ${currency}`
              };
            });
          }
        })
        .catch(error => console.error('Error fetching package details:', error));
      },
      addToCart(packageId) {
        let url = sessionStorage.getItem('userInfo') ? '/api/user/cart' : '/api/session/cart';
        const userInfo = sessionStorage.getItem('userInfo');
        let headers = {};
        if (userInfo) {
          try {
            const parsedUserInfo = JSON.parse(userInfo);
            const token = parsedUserInfo.token;

            if (token) {
              // Add the token to the request headers
              headers['Authorization'] = `Bearer ${token}`;
              headers['Content-type'] = 'application/json';
            }
          } catch (error) {
            console.error('Error parsing userInfo from sessionStorage:', error);
          }
        }

        axios.post(url, {
          course_package_id: packageId,
          quantity: 1 // Default quantity
        }, { headers })
        .then(response => {
          // Handle successful response
          console.log('Item added to cart successfully:', response.data);
          this.$router.push({ name: 'CartEn'});
          // Optionally update the UI or show a success message
        })
        .catch(error => {
          // Handle error response
          console.error('Error adding item to cart:', error.response ? error.response.data : error.message);
          // Optionally show an error message to the user
        });
      }
    },
    mounted() {
      this.fetchPackagePrices();
    },
  };
</script>

<style src="@/assets/styles/en/packages.css" scoped></style>
<style src="@/assets/styles/ar/packages.css" scoped></style>