<template>
  <div class="shopping-cart">
    <main class="cart-main">
      <div class="cart-info">
        <h1>سلة المشتريات</h1>
      </div>
    </main>
    <div class="cart-page">
      <div v-if="cartItems.length === 0" class="empty-cart">
        <img src="@/assets/images/cart.png" alt="Empty Cart Image" />
        <h2>لا يوجد لديك شىء في السلة</h2>
        <p>ابدأ رحلتك التعليمية الآن اشترك في دوراتنا واستفد من تجربة تعلم فريدة!</p>
        <button class="back-to-courses" @click="goToCourses">العودة للدورات</button>
      </div>
      <div v-else class="cart-content">
        <div class="cart-container cart-container-1">
          <div class="order-section">
            <table class="order-table">
              <thead>
                <tr>
                  <th>الدورة</th>
                  <th>الكمية</th>
                  <th>السعر</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr class="order-item" v-for="(item, index) in cartItems" :key="index">
                  <td class="course">{{ item.course_package.name }}</td>
                  <td class="quantity">
                    <button @click="decreaseQuantity(item.id)">-</button>
                    <label class="p-4">{{ item.quantity }}</label>
                    <input type="hidden" v-model="item.quantity" readonly/>
                    <button @click="increaseQuantity(item.id)">+</button>
                  </td>
                  <td class="price">{{ item.single_price }} {{ cartSummary.currency }}</td>
                  <td>
                    <button class="remove-button" @click="removeItem(item.id)">
                      <svg aria-hidden="true" class="svg-inline--fa fa-xmark" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg="">
                        <path fill="currentColor" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
                      </svg>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="order-table-mobile" v-for="(item, index) in cartItems" :key="index">
              <tr>
                <td>الدورة</td>
                <td class="course">{{ item.course_package.name }}</td>
              </tr>
              <tr class="order-item">
                <td>الكمية</td>
                <td class="quantity">
                  <button @click="decreaseQuantity(item.id)">-</button>
                  <label class="p-4">{{ item.quantity }}</label>
                  <input type="hidden" v-model="item.quantity" readonly/>
                  <button @click="increaseQuantity(item.id)">+</button>
                </td>
              </tr>
              <tr class="price-row">
                <td>السعر</td>
                <td class="price">{{ item.single_price }} {{ cartSummary.currency }}</td>
              </tr>
              <tr class="remove-row">
                <td></td>
                <td colspan="2">
                  <button class="remove-button" @click="removeItem(item.id)">
                    <svg aria-hidden="true" class="svg-inline--fa fa-xmark" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg="">
                      <path fill="currentColor" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
                    </svg>
                  </button>
                </td>
              </tr>
            </table>
            <button class="buy-another-course" @click="goToCourses">
              →
              شراء دورة اخرى
            </button>
            <div v-if="orderUpdated" class="order-update-message">
              تم تحديث سلة المشتريات.
            </div>
          </div>
        </div>
        <div class="cart-container cart-container-2">
          <div v-if="successMessage" class="success-message">
            {{ successMessage }}
          </div>
          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
          <div class="coupon-section">
            <label for="coupon-code">لديك كود خصم ؟</label>
            <div class="coupon-input">
              <input type="text" id="coupon-code" placeholder="Coupon code" v-model="couponCode"/>
              <button v-if="!cartSummary.coupon_code" @click="applyCoupon" class="apply-button">تطبيق</button>
              <button v-if="cartSummary.coupon_code" @click="removeCoupon" class="remove-coupon-button">إزالة الكوبون</button>
            </div>
          </div>
          <table class="price-table">
            <tr>
              <td v-if="cartSummary.total_price_discount == 0">السعر النهائي</td>
              <td v-else>السعر المبدائي</td>
              <td>{{ cartSummary.total_price }} {{ cartSummary.currency }}</td>
            </tr>
            <tr v-if="cartSummary.family_or_friend_discount > 0">
              <td>خصومات الأصدقاء/العائلة</td>
              <td>
                <div class="remove-coupon-section">
                    - {{ cartSummary.family_or_friend_discount }} {{ cartSummary.currency }}
                </div>
              </td>
            </tr>
            <tr v-if="cartSummary.coupon_code">
              <td>الخصم</td>
              <td>
                <div class="remove-coupon-section">
                  - {{ cartSummary.discount }} {{ cartSummary.currency }}
                </div>
              </td>
            </tr>
            <tr v-if="cartSummary.total_price_discount != 0">
              <td>السعر النهائي</td>
              <td>{{ cartSummary.total_price_discount }} {{ cartSummary.currency }}</td>
            </tr>
          </table>
          <button class="confirm-button" @click="showAuthModal">تأكيد الطلب</button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showModal" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <form class="w-full max-w-sm" @submit.prevent="goToCheckout">
        <div v-if="validationErrorMessage" class="validation-container">
          <p id="validationError" class="error" v-html="validationErrorMessage"></p>
          <span class="error">,</span> <a v-if="link" href="https://front.monglish.academy/login" class="text-linkkk">Login</a> <span class="error">or enter another email</span>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="firstname">الاسم الأول</label>
          </div>
          <div class="md:w-2/3">
            <input class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" type="text" id="firstname" v-model="firstname" />
            <p v-if="errors.firstname" class="error">{{ errors.firstname }}</p>
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="lastname">الاسم الأخير</label>
          </div>
          <div class="md:w-2/3">
            <input class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" type="text" id="lastname" v-model="lastname" />
            <p v-if="errors.lastname" class="error">{{ errors.lastname }}</p>
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="country">البلد / المنطقة</label>
          </div>
          <div class="md:w-2/3 relative">
            <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="country" v-model="country">
              <option value="أذربيجان">أذربيجان</option>
              <option value="أرمينيا">أرمينيا</option>
              <option value="أروبا">أروبا</option>
              <option value="أستراليا">أستراليا</option>
              <option value="أفغانستان">أفغانستان</option>
              <option value="ألبانيا">ألبانيا</option>
              <option value="ألمانيا">ألمانيا</option>
              <option value="أنغولا">أنغولا</option>
              <option value="أنتيغوا وبربودا">أنتيغوا وبربودا</option>
              <option value="أندورا">أندورا</option>
              <option value="أوزبكستان">أوزبكستان</option>
              <option value="أوغندا">أوغندا</option>
              <option value="أوكرانيا">أوكرانيا</option>
              <option value="أيسلندا">أيسلندا</option>
              <option value="إثيوبيا">إثيوبيا</option>
              <option value="إريتريا">إريتريا</option>
              <option value="إسبانيا">إسبانيا</option>
              <option value="إستونيا">إستونيا</option>
              <option value="الأرجنتين">الأرجنتين</option>
              <option value="الأردن">الأردن</option>
              <option value="الإمارات العربية المتحدة">الإمارات العربية المتحدة</option>
              <option value="البحرين">البحرين</option>
              <option value="البرازيل">البرازيل</option>
              <option value="البرتغال">البرتغال</option>
              <option value="البهاما">البهاما</option>
              <option value="البوسنة والهرسك">البوسنة والهرسك</option>
              <option value="الجبل الأسود">الجبل الأسود</option>
              <option value="الجزائر">الجزائر</option>
              <option value="الدانمارك">الدانمارك</option>
              <option value="الدومينيكا">الدومينيكا</option>
              <option value="السلفادور">السلفادور</option>
              <option value="السنغال">السنغال</option>
              <option value="السودان">السودان</option>
              <option value="السويد">السويد</option>
              <option value="الصحراء الغربية">الصحراء الغربية</option>
              <option value="الصومال">الصومال</option>
              <option value="الصين">الصين</option>
              <option value="العراق">العراق</option>
              <option value="الفاتيكان">الفاتيكان</option>
              <option value="الفلبين">الفلبين</option>
              <option value="الكاميرون">الكاميرون</option>
              <option value="الكونغو">الكونغو</option>
              <option value="الكويت">الكويت</option>
              <option value="المغرب">المغرب</option>
              <option value="المجر">المجر</option>
              <option value="المحيط الهندي البريطاني">المحيط الهندي البريطاني</option>
              <option value="المكسيك">المكسيك</option>
              <option value="المملكة المتحدة">المملكة المتحدة</option>
              <option value="النرويج">النرويج</option>
              <option value="النمسا">النمسا</option>
              <option value="النيجر">النيجر</option>
              <option value="الهند">الهند</option>
              <option value="الولايات المتحدة">الولايات المتحدة</option>
              <option value="اليابان">اليابان</option>
              <option value="اليمن">اليمن</option>
              <option value="اليونان">اليونان</option>
              <option value="إندونيسيا">إندونيسيا</option>
              <option value="إيران">إيران</option>
              <option value="إيرلندا">إيرلندا</option>
              <option value="بابوا غينيا الجديدة">بابوا غينيا الجديدة</option>
              <option value="باراغواي">باراغواي</option>
              <option value="باكستان">باكستان</option>
              <option value="بالاو">بالاو</option>
              <option value="بربادوس">بربادوس</option>
              <option value="بروناي دار السلام">بروناي دار السلام</option>
              <option value="بلجيكا">بلجيكا</option>
              <option value="بلغاريا">بلغاريا</option>
              <option value="بليز">بليز</option>
              <option value="بنغلاديش">بنغلاديش</option>
              <option value="بنما">بنما</option>
              <option value="بنين">بنين</option>
              <option value="بوتان">بوتان</option>
              <option value="بوتسوانا">بوتسوانا</option>
              <option value="بوركينا فاسو">بوركينا فاسو</option>
              <option value="بوروندي">بوروندي</option>
              <option value="بوليفيا">بوليفيا</option>
              <option value="بولندا">بولندا</option>
              <option value="بونير وسانت يوستاتيوس وسابا">بونير وسانت يوستاتيوس وسابا</option>
              <option value="بيرو">بيرو</option>
              <option value="تانزانيا">تانزانيا</option>
              <option value="تايلاند">تايلاند</option>
              <option value="تايوان">تايوان</option>
              <option value="تركمانستان">تركمانستان</option>
              <option value="تركيا">تركيا</option>
              <option value="ترينيداد وتوباغو">ترينيداد وتوباغو</option>
              <option value="تشاد">تشاد</option>
              <option value="تشيلي">تشيلي</option>
              <option value="توجو">توجو</option>
              <option value="توفالو">توفالو</option>
              <option value="تونس">تونس</option>
              <option value="تيمور الشرقية">تيمور الشرقية</option>
              <option value="جامايكا">جامايكا</option>
              <option value="جامبيا">جامبيا</option>
              <option value="جبل طارق">جبل طارق</option>
              <option value="جرينادا">جرينادا</option>
              <option value="جزر تركس وكايكوس">جزر تركس وكايكوس</option>
              <option value="جزر سليمان">جزر سليمان</option>
              <option value="جزر فوكلاند">جزر فوكلاند</option>
              <option value="جزر كوك">جزر كوك</option>
              <option value="جزر مارشال">جزر مارشال</option>
              <option value="جزر ماريانا الشمالية">جزر ماريانا الشمالية</option>
              <option value="جزر هيرد وماكدونالد">جزر هيرد وماكدونالد</option>
              <option value="جزيرة الكريسماس">جزيرة الكريسماس</option>
              <option value="جزيرة بوفيه">جزيرة بوفيه</option>
              <option value="جزيرة مان">جزيرة مان</option>
              <option value="جزر العذراء البريطانية">جزر العذراء البريطانية</option>
              <option value="جزر العذراء الأمريكية">جزر العذراء الأمريكية</option>
              <option value="جزر كايمان">جزر كايمان</option>
              <option value="جزر كوكوس">جزر كوكوس</option>
              <option value="جمهورية أفريقيا الوسطى">جمهورية أفريقيا الوسطى</option>
              <option value="جمهورية التشيك">جمهورية التشيك</option>
              <option value="جمهورية الكونغو الديمقراطية">جمهورية الكونغو الديمقراطية</option>
              <option value="جنوب أفريقيا">جنوب أفريقيا</option>
              <option value="جورجيا">جورجيا</option>
              <option value="جيبوتي">جيبوتي</option>
              <option value="دومينيكا">دومينيكا</option>
              <option value="رواندا">رواندا</option>
              <option value="روسيا">روسيا</option>
              <option value="رومانيا">رومانيا</option>
              <option value="زامبيا">زامبيا</option>
              <option value="زيمبابوي">زيمبابوي</option>
              <option value="ساحل العاج">ساحل العاج</option>
              <option value="ساموا">ساموا</option>
              <option value="سان مارينو">سان مارينو</option>
              <option value="سانت بيير وميكلون">سانت بيير وميكلون</option>
              <option value="سانت فنسنت والغرينادين">سانت فنسنت والغرينادين</option>
              <option value="سانت كيتس ونيفيس">سانت كيتس ونيفيس</option>
              <option value="سانت لوسيا">سانت لوسيا</option>
              <option value="سانت مارتن">سانت مارتن</option>
              <option value="سانت هيلينا">سانت هيلينا</option>
              <option value="ساو تومي وبرينسيب">ساو تومي وبرينسيب</option>
              <option value="سريلانكا">سريلانكا</option>
              <option value="سفالبارد وجان مايان">سفالبارد وجان مايان</option>
              <option value="سلوفاكيا">سلوفاكيا</option>
              <option value="سلوفينيا">سلوفينيا</option>
              <option value="سنغافورة">سنغافورة</option>
              <option value="سوازيلاند">سوازيلاند</option>
              <option value="سورينام">سورينام</option>
              <option value="سويسرا">سويسرا</option>
              <option value="سيراليون">سيراليون</option>
              <option value="سيشيل">سيشيل</option>
              <option value="صربيا">صربيا</option>
              <option value="طاجيكستان">طاجيكستان</option>
              <option value="عمان">عمان</option>
              <option value="غامبيا">غامبيا</option>
              <option value="غانا">غانا</option>
              <option value="غرينادا">غرينادا</option>
              <option value="غواتيمالا">غواتيمالا</option>
              <option value="غوام">غوام</option>
              <option value="غويانا">غويانا</option>
              <option value="غينيا">غينيا</option>
              <option value="غينيا الاستوائية">غينيا الاستوائية</option>
              <option value="غينيا بيساو">غينيا بيساو</option>
              <option value="فانواتو">فانواتو</option>
              <option value="فرنسا">فرنسا</option>
              <option value="فلسطين">فلسطين</option>
              <option value="فنزويلا">فنزويلا</option>
              <option value="فنلندا">فنلندا</option>
              <option value="فيجي">فيجي</option>
              <option value="قبرص">قبرص</option>
              <option value="قرغيزستان">قرغيزستان</option>
              <option value="قطر">قطر</option>
              <option value="كازاخستان">كازاخستان</option>
              <option value="كاليدونيا الجديدة">كاليدونيا الجديدة</option>
              <option value="كرواتيا">كرواتيا</option>
              <option value="كمبوديا">كمبوديا</option>
              <option value="كندا">كندا</option>
              <option value="كوبا">كوبا</option>
              <option value="كوريا الجنوبية">كوريا الجنوبية</option>
              <option value="كوريا الشمالية">كوريا الشمالية</option>
              <option value="كوستاريكا">كوستاريكا</option>
              <option value="كولومبيا">كولومبيا</option>
              <option value="كيريباتي">كيريباتي</option>
              <option value="كينيا">كينيا</option>
              <option value="لاتفيا">لاتفيا</option>
              <option value="لاوس">لاوس</option>
              <option value="لبنان">لبنان</option>
              <option value="لوكسمبورغ">لوكسمبورغ</option>
              <option value="ليبيا">ليبيا</option>
              <option value="ليبيريا">ليبيريا</option>
              <option value="ليتوانيا">ليتوانيا</option>
              <option value="ليختنشتاين">ليختنشتاين</option>
              <option value="ليسوتو">ليسوتو</option>
              <option value="مالطا">مالطا</option>
              <option value="مالاوي">مالاوي</option>
              <option value="ماليزيا">ماليزيا</option>
              <option value="ماكاو">ماكاو</option>
              <option value="مدغشقر">مدغشقر</option>
              <option value="مصر">مصر</option>
              <option value="مقدونيا">مقدونيا</option>
              <option value="مالديف">مالديف</option>
              <option value="مالي">مالي</option>
              <option value="مانيا">مانيا</option>
              <option value="موريتانيا">موريتانيا</option>
              <option value="موريشيوس">موريشيوس</option>
              <option value="موزمبيق">موزمبيق</option>
              <option value="مولدوفا">مولدوفا</option>
              <option value="موناكو">موناكو</option>
              <option value="مونتسيرات">مونتسيرات</option>
              <option value="ميانمار">ميانمار</option>
              <option value="ناميبيا">ناميبيا</option>
              <option value="ناورو">ناورو</option>
              <option value="نيبال">نيبال</option>
              <option value="نيجيريا">نيجيريا</option>
              <option value="نيكاراغوا">نيكاراغوا</option>
              <option value="نيوزيلندا">نيوزيلندا</option>
              <option value="نيوي">نيوي</option>
              <option value="هايتي">هايتي</option>
              <option value="هندوراس">هندوراس</option>
              <option value="هولندا">هولندا</option>
              <option value="هونغ كونغ">هونغ كونغ</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
            </div>
            <p v-if="errors.country" class="error">{{ errors.country }}</p>
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="phone">رقم الجوال</label>
          </div>
          <div class="md:w-2/3">
            <input class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" type="text" id="phone" v-model="phone" />
            <p v-if="errors.phone" class="error">{{ errors.phone }}</p>
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="email">البريد الإلكتروني</label>
          </div>
          <div class="md:w-2/3">
            <input class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" type="email" id="email" v-model="email" />
            <p v-if="errors.email" class="error">{{ errors.email }}</p>
          </div>
        </div>
        
        <button type="submit" class="checkout-button">إتمام الطلب</button>
      </form>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    data() {
      return {
        orderUpdated: false,
        showModal:false,
        cartItems: [], // Initialize cartItems as an empty array
        cartSummary: {
          total_price: 0,
          total_price_discount: 0,
          family_or_friend_discount: 0,
          currency: '',
          total_items_count: 0,
          coupon_code: null, // Ensure coupon_code is defined
          discount: 0, // Add discount to cartSummary
        },
        firstname: '',
        lastname: '',
        country: '',
        phone: '',
        email: '',
        couponCode: '',      // Holds the coupon code input
        successMessage: '',  // Holds success message
        errors: {}, // To store form errors
        errorMessage: '',    // Holds error message
        validationErrorMessage: '',
        link: false,
      };
    },
    methods: {
      async fetchCartItems() {
        let url = sessionStorage.getItem('userInfo') ? '/api/user/cart' : '/api/session/cart';

        const userInfo = sessionStorage.getItem('userInfo');
        let headers = {};
        if (userInfo) {
          try {
            const parsedUserInfo = JSON.parse(userInfo);
            const token = parsedUserInfo.token;

            if (token) {
              headers['Authorization'] = `Bearer ${token}`;
            }
          } catch (error) {
            console.error('Error parsing userInfo from sessionStorage:', error);
          }
        }

        axios.get(url, { headers })
          .then(response => {
            // Store cart items in data
            this.cartItems = response.data.data.items || [];
            this.orderUpdated = false; // Reset update status
            this.cartSummary = response.data.data;
            if(this.cartSummary.coupon_code){
              this.couponCode = this.cartSummary.coupon_code['code'];
            }
          })
          .catch(error => {
            if (error.response && error.response.status === 404) {
            //   // If the cart is not found, treat it as empty
              this.cartItems = [];
              this.cartSummary = {};
              console.warn('Cart is empty or was deleted:', error.response.data);
            } else {
              console.error('Error fetching cart items:', error.response ? error.response.data : error.message);
            }
          });
      },
      async increaseQuantity(itemId) {
        let url = sessionStorage.getItem('userInfo') ? `/api/user/cart/increase/${itemId}` : `/api/session/cart/increase/${itemId}`;

        const userInfo = sessionStorage.getItem('userInfo');
        let headers = {};
        if (userInfo) {
          try {
            const parsedUserInfo = JSON.parse(userInfo);
            const token = parsedUserInfo.token;

            if (token) {
              headers['Authorization'] = `Bearer ${token}`;
            }
          } catch (error) {
            console.error('Error parsing userInfo from sessionStorage:', error);
          }
        }

        axios.get(url, { headers })
          .then(() => this.fetchCartItems()) // Refresh cart items
          .catch(error => {
            console.error('Error increasing item quantity:', error);
          });
      },
      async decreaseQuantity(itemId) {
        let url = sessionStorage.getItem('userInfo') ? `/api/user/cart/decrease/${itemId}` : `/api/session/cart/decrease/${itemId}`;

        const userInfo = sessionStorage.getItem('userInfo');
        let headers = {};
        if (userInfo) {
          try {
            const parsedUserInfo = JSON.parse(userInfo);
            const token = parsedUserInfo.token;

            if (token) {
              headers['Authorization'] = `Bearer ${token}`;
            }
          } catch (error) {
            console.error('Error parsing userInfo from sessionStorage:', error);
          }
        }

        axios.get(url, { headers })
          .then(() => this.fetchCartItems()) // Refresh cart items
          .catch(error => {
            console.error('Error decreasing item quantity:', error);
          });
      },
      async removeItem(itemId) {
        let url = sessionStorage.getItem('userInfo') ? `/api/user/cart/remove/${itemId}` : `/api/session/cart/remove/${itemId}`;

        const userInfo = sessionStorage.getItem('userInfo');
        let headers = {};
        if (userInfo) {
          try {
            const parsedUserInfo = JSON.parse(userInfo);
            const token = parsedUserInfo.token;

            if (token) {
              headers['Authorization'] = `Bearer ${token}`;
            }
          } catch (error) {
            console.error('Error parsing userInfo from sessionStorage:', error);
          }
        }

        axios.get(url, { headers })
        .then(() => this.fetchCartItems())
        .catch(error => {
          this.errorMessage = 'Error removing item.';
          console.error('Error removing item:', error);
        });
      },
      async applyCoupon() {
        // Reset messages
        this.successMessage = '';
        this.errorMessage = '';

        if (!this.couponCode.trim()) {
          this.errorMessage = 'Please enter a valid coupon code.';
          return;
        }
        try {
          let url = sessionStorage.getItem('userInfo') ? '/api/user/cart/apply-coupon' : '/api/session/cart/apply-coupon';

          const userInfo = sessionStorage.getItem('userInfo');
          let headers = {};
          if (userInfo) {
            try {
              const parsedUserInfo = JSON.parse(userInfo);
              const token = parsedUserInfo.token;

              if (token) {
                headers['Authorization'] = `Bearer ${token}`;
              }
            } catch (error) {
              console.error('Error parsing userInfo from sessionStorage:', error);
            }
          }

          const response = await axios.post(url, { coupon_code: this.couponCode }, { headers });

          if (response.data.success) {
              this.cartSummary = response.data.data.cartSummary;
              this.successMessage = response.data.message;
              this.couponCode = this.cartSummary.coupon_code; // Clear the input field on success
            } else {
              this.errorMessage = response.data.message;
            }
        } catch (error) {
          this.errorMessage = 'Failed to apply coupon. Please try again.';
        }
      },
      async removeCoupon() {
        // Reset messages
        this.successMessage = '';
        this.errorMessage = '';

        try {
          let url = sessionStorage.getItem('userInfo') ? '/api/user/cart/remove-coupon' : '/api/session/cart/remove-coupon';

          const userInfo = sessionStorage.getItem('userInfo');
          let headers = {};
          if (userInfo) {
            try {
              const parsedUserInfo = JSON.parse(userInfo);
              const token = parsedUserInfo.token;

              if (token) {
                headers['Authorization'] = `Bearer ${token}`;
              }
            } catch (error) {
              console.error('Error parsing userInfo from sessionStorage:', error);
            }
          }

          const response = await axios.post(url, {}, { headers });
          console.log(response.data);
          if (response.data.success) {
            this.cartSummary = response.data.data.cartSummary || {};
            this.successMessage = response.data.message;
            this.couponCode = ''; // Clear the input field when coupon is removed
          } else {
            this.errorMessage = response.data.message;
          }
        } catch (error) {
          this.errorMessage = 'Failed to remove coupon. Please try again.';
        }
      },
      goToCourses() {
        this.$router.push('/ar/packages');
      },
      validateForm() {
        this.errors = {};

        if (!this.firstname) {
          this.errors.firstname = 'الاسم الأول مطلوب';
        }

        if (!this.lastname) {
          this.errors.lastname = 'الاسم الأخير مطلوب';
        }

        if (!this.country) {
          this.errors.country = 'البلد / المنطقة مطلوب';
        }

        if (!this.phone) {
          this.errors.phone = 'رقم الجوال مطلوب';
        }

        if (!this.email) {
          this.errors.email = 'البريد الإلكتروني مطلوب';
        } else if (!this.isValidEmail(this.email)) {
          this.errors.email = 'البريد الإلكتروني غير صالح';
        }
      },
      isValidEmail(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
      },
      goToCheckout() {
        
        let url = sessionStorage.getItem('userInfo')
          ? '/api/user/checkout'
          : '/api/session/checkout';

        const userInfo = sessionStorage.getItem('userInfo');
        let headers = {};
        let formData = {};
        if (userInfo) {
          try {
            const parsedUserInfo = JSON.parse(userInfo);
            const token = parsedUserInfo.token;

            if (token) {
              headers['Authorization'] = `Bearer ${token}`;
            }
          } catch (error) {
            console.error('Error parsing userInfo from sessionStorage:', error);
          }
        } else{
          formData = {
            firstname: this.firstname,
            lastname: this.lastname,
            country: this.country,
            phone: this.phone,
            email: this.email,
          };
          this.validateForm();
        }

        // If there are no errors, submit the form
        if (Object.keys(this.errors).length === 0) {

          axios.post(url, formData, { headers })
          .then((response) => {
            // Handle successful form submission
            console.log('Order checkout successfully:', response.data);
            if (response.data.success) {
              // Redirect to the URL in the response data
              window.location.href = response.data.data;
            } else {
              console.error('Error:', response.data.message);
              // Optionally, display an error message to the user
            }
          })
          .catch((error) => {
            // Handle errors
            console.error('Error submitting form:', error.response.data);
            this.validationErrorMessage = error.response.data.data.error || 'حدث خطأ أثناء إرسال النموذج. حاول مرة أخرى.';
            if((error.response.data.message).includes('User Exists')){
              this.link = true;
            }
          });
        }
      },
      showAuthModal() {
        if (sessionStorage.getItem('userInfo')) {
          this.goToCheckout();
        } else {
          this.showModal = true;
        }
      },
      closeModal() {
        this.showModal = false;
      },
    },
    computed: {
      totalPrice() {
        return this.cartItems.reduce((total, item) => total + item.single_price * item.quantity, 0);
      }
    },
    mounted() {
      this.fetchCartItems(); // Fetch cart items when component is mounted
    }
  };
</script>

<style scoped>
  .shopping-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('@/assets/images/cart_bg.png');
    background-size: cover;
    background-position: center;
    direction: rtl;
    margin-top: -85px;
  }

  .cart-main {
    display: flex;
    width: 100%;
  }

  .cart-info {
    text-align: right;
    opacity: 0.9;
    margin-bottom: 5%;
    margin-right: 5%;
  }

  .cart-info h1 {
    font-size: 3rem;
    color: #fff;
    font-family: 'DIN Next LT Arabic';
    font-weight: 500;
    margin-top: 200px;
  }

  .cart-page {
    display: flex;
    font-family: 'DIN Next LT Arabic';
    font-weight: 500;
    justify-content: center;
    width: 100%;
    background-color: white;
  }

  .empty-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    gap: 20px;
  }

  .empty-cart img {
    width: 20vw;
  }

  .empty-cart h2 {
    font-size: 2rem;
    color: #333;
    line-height: 80%;
  }

  .empty-cart p {
    font-size: 1.2rem;
    color: #666;
    line-height: 100%;
  }

  .back-to-courses {
    background: linear-gradient(45deg, #ff9442, #ff6f00);
    color: white;
    border: none;
    border-radius: 15px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 22px;
    margin-bottom: 20px;
    font-family: 'DIN Next LT Arabic';
    font-weight: 500;
  }

  /* cart items */
  .cart-content {
    display: flex;
    width: 100%;
  }

  .cart-container {
    padding: 30px;
    border-radius: 15px;
    width: 100%;
    margin-bottom: 20px;
  }

  .cart-container-1 {
    max-width: 70%;
    position: relative;
    right: 0;
    top: -90px;
  }

  .cart-container-2 {
    max-width: 30%;
    position: relative;
    right: 0;
    top: -50px;
    background: white;
  }

  .coupon-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .coupon-input {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  #coupon-code {
    padding: 15px;
    border-radius: 15px;
    margin-left: 10px;
    border: 1px solid #ccc;
    width: 60%;
  }

  .remove-coupon-section{
    color:#ff1919;
  }

  .apply-button, .remove-coupon-button {
    background: linear-gradient(45deg, #ff9442, #ff6f00);
    color: white;
    border: none;
    border-radius: 15px;
    padding: 10px;
    cursor: pointer;
    font-size: 18px;
    font-family: 'DIN Next LT Arabic';
    font-weight: 500;
    width: 40%;
  }

  .remove-coupon-button{
    background: linear-gradient(45deg, #fd7878, #fa5353);
  }

  .price-table {
    width: 100%;
    margin-bottom: 20px;
    border-collapse: collapse;
    font-weight: bold;
    font-family: 'DIN Next LT Arabic';
    font-size: 20px;
  }

  .price-table td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
    font-size: 18px;
    direction: ltr;
  }

  .confirm-button {
    background: linear-gradient(45deg, #ff9442, #ff6f00);
    color: white;
    border: none;
    border-radius: 15px;
    padding: 15px;
    width: 100%;
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .order-section {
    padding: 15px;
  }

  .order-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-family: 'DIN Next LT Arabic';
    font-weight: 500;
  }

  .order-table-mobile {
    display: none;
  }

  .order-table th,
  .order-table td {
    border: 1px solid #ddd;
    text-align: center;
    padding: 10px;
    border-left: 0;
    border-right: 0;
  }

  .order-table th{
    border-top: 0;
    padding-bottom: 20px;
    color: white;
  }

  .order-item {
    background-color: white;
  }

  .price {
    color: #f47920;
    font-size: 26px;
    font-weight: 600;
  }

  .quantity {
    align-items: center;
    justify-content: center;
  }

  .quantity input {
    width: 40px;
    text-align: center;
    margin: 0 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
  }

  .quantity button {
    background-color: #fff;
    border: 1px solid #ccc;
    color: #808b94;
    padding: 5px;
    font-size: 18px;
    cursor: pointer;
  }

  .course {
    font-size: 26px;
    font-weight: bold;
    transition: color 0.3s;
  }

  .course:hover {
    color: #f47920;
  }

  .remove-button {
    background-color: transparent;
    border: 1px solid #F47D21;
    border-radius: 50%;
    color: #f47920;
    cursor: pointer;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, color 0.3s;
  }

  .remove-button:hover {
    background-color: #f47920;
    color: white;
  }

  .buy-another-course {
    background-color: #165e84;
    color: #fff;
    padding: 16px 20px 16px 20px;
    margin: 0px 0px 0px 0px;
    font-family: 'DIN Next LT Arabic-n', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: center;
  }
  /* end cart item */

  .order-update-message {
    background-color: #d4edda;
    color: #155724;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
  }

  .success-message {
    color: green;
    margin-top: 1em;
  }

  .error-message {
    color: red;
    margin-top: 1em;
  }

  .validation-container{
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .error {
    color: red;
  }

  .text-linkkk{
    color:#0f73a8 !important;
    text-decoration: underline !important;
  }

  .text-linkkk:hover{
    color:#034b72 !important;
    text-decoration: none !important;
  }

  .checkout-form-group {
    margin-bottom: 18px;
    text-align: right;
  }
  .checkout-form-group label {
    margin-bottom: 100px;
    margin-left:10px;
    font-size: 18px;
    color: #333;
  }
  .checkout-form-group input, .checkout-form-group select {
    padding: 16px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 50%;
    text-align: right;
  }

  .checkout-button {
    background: linear-gradient(45deg, #ff9442, #ff6f00);
    color: #fff;
    padding: 16px 24px;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
    margin-top: 20px;
    transition: background-color 0.3s ease;
    width: 100%;
  }

  .checkout-button:hover {
    background-color: #ff9442;
  }

  .checkout-form {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 900px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  .personal-section {
    width: 100%;
  }

  .modal {
    display: block;
    position: fixed;
    z-index: 2000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
  }

  .modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 25%;
  }

  .modal-content form{
    margin-top: 50px;
  }

  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  @media (min-width: 320px) and (max-width: 479px) {
    .cart-content{
      flex-direction: column;
    }
    .cart-container-1 {
      max-width: 100%;
      position: relative;
      right: 0;
      top: -40px;
      padding: 10px;
    }

    .order-table{
      display: none;
    }

    .order-table-mobile {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
      font-family: 'DIN Next LT Arabic';
      font-weight: 500;
    }

    .order-table-mobile tr {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ddd;
      margin: 10px;
    }

    .remove-row {
      border-bottom: 0 !important;
      flex-direction: row-reverse;
    }

    .price-row td{
      color: #f47920;
      font-size: 26px;
      font-weight: 600;
    }

    .order-table-mobile td {  
      text-align: center;
      padding: 10px;
      border-left: 0;
      border-right: 0;
    }

    .order-item {
      background-color: white;
    }

    .price {
      color: #f47920;
      font-size: 26px;
      font-weight: 600;
    }

    .quantity {
      align-items: center;
      justify-content: center;
    }

    .quantity input {
      width: 40px;
      text-align: center;
      margin: 0 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 5px;
    }

    .quantity button {
      background-color: #fff;
      border: 1px solid #ccc;
      color: #808b94;
      padding: 5px;
      font-size: 18px;
      cursor: pointer;
    }

    .course {
      font-size: 26px;
      font-weight: bold;
      transition: color 0.3s;
    }

    .course:hover {
      color: #f47920;
    }

    .remove-button {
      background-color: transparent;
      border: 1px solid #F47D21;
      border-radius: 50%;
      color: #f47920;
      cursor: pointer;
      width: 25px;
      height: 25px;
      align-items: center;
      justify-content: center;
      transition: background-color 0.3s, color 0.3s;
    }

    .remove-button:hover {
      background-color: #f47920;
      color: white;
    }

    .cart-container-2 {
      max-width: 100%;
    }

    .price-table td {
      direction: rtl;
    }
    
    .modal-content{
      width: 90%;
    }
  }
</style>
