<template>
  <div class="content-slider-container">
    <!-- Text Content Section -->
    <div class="text-content">
      <h1>خبراؤنا من الولايات المتحدة !</h1>
      <h2>اننا عالميون حقًا <span>بكل الطرق!</span></h2>
      <p>
         نحن فخورون بضم نخبة من الخبراء الأمريكيين في اللغة الإنجليزية إلى فريقنا. خبرتهم الواسعة وثقافتهم اللغوية الأصيلة تضمن لك تجربة تعلم غنية ومتميزة. استفد من معرفتهم العميقة باللغة الإنجليزية الأمريكية لتطوير مهاراتك اللغوية.
      </p>
    </div>

    <!-- Slider Section -->
    <div class="video-slider-component">
      <div class="slider-container">
        <button class="slider-nav left" @click="prevSlide">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
          </svg>
        </button>
        <div class="image-container">
          <img
            :src="currentImage.src"
            :alt="currentImage.alt"
            @click="openPopup(currentImage.videoSrc)"
            class="image-thumbnail"
          />
        </div>
        <button class="slider-nav right" @click="nextSlide">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
          </svg>
        </button>
      </div>
    </div>

    <!-- Popup Overlay -->
    <div v-if="isPopupOpen" class="popup-overlay" @click="closePopup">
      <div class="popup-content" @click.stop>
        <button class="close-button" @click="closePopup">&times;</button>
        <iframe
          v-if="currentVideoSrc"
          :src="currentVideoSrc"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="popup-video"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isPopupOpen: false,
      currentVideoSrc: '',
      currentSlide: 0,
      images: [
        {
          videoSrc: 'https://www.youtube.com/embed/8rMBBTvVX0o?autoplay=1',
          src: require('@/assets/images/video/5.png'),
          alt: 'Video 1 Thumbnail',
        },
        {
          videoSrc: 'https://www.youtube.com/embed/tV_1oijlb_I?autoplay=1',
          src: require('@/assets/images/video/4.png'),
          alt: 'Video 2 Thumbnail',
        },
      ],
    };
  },
  computed: {
    currentImage() {
      return this.images[this.currentSlide];
    },
  },
  methods: {
    openPopup(videoSrc) {
      this.currentVideoSrc = videoSrc;
      this.isPopupOpen = true;
    },
    closePopup() {
      this.isPopupOpen = false;
      this.currentVideoSrc = '';
    },
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.images.length;
    },
    prevSlide() {
      this.currentSlide =
        (this.currentSlide - 1 + this.images.length) % this.images.length;
    },
  },
};
</script>

<style scoped>
.content-slider-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: white;
  direction: rtl;
}

.text-content {
  flex: 1;
  padding-right: 5%;
}

.text-content h1 {
  color: #165e84;
  font-size: 48px;
  font-weight: 700;
  font-family: 'DIN Next LT Arabic-n';
  margin-bottom: 1%;
}

.text-content h2{
  color: #3D3D3D;
  font-size: 40px;
  font-weight: 500;
  font-family: 'DIN Next LT Arabic';
  margin-bottom: 3%;
}
.text-content h2 span{
  color: #165e84
}
.text-content p {
  color: #4b4b4b;
  font-size: 20px;
  width: 70%;
  margin-bottom: 3%;
}

.text-content button {
  padding: 12px;
  background: linear-gradient(45deg, #fe9b4f, #f47e23);
  color: white;
  border: none;
  border-radius: 25px;
  width: 40%;
  cursor: pointer;
  font-size: 20px;
}

.video-slider-component {
  flex: 1;
}

.slider-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container {
  display: flex;
}

.image-thumbnail {
  width: 400px;
  object-fit: cover;
  cursor: pointer;
}

.slider-nav {
  color: #165e84;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  background-color: transparent;
  transition: color 0.3s ease;
  border: none;
}

.slider-nav.left {
  left: 5vw;
}

.slider-nav.right {
  right: 5vw;
}

.slider-nav:hover {
  color: #2178a6;
}

.slider-nav:active {
  color: #fff;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  width: 26vw;
  height: 95vh;  
  overflow: hidden;
  position: relative;
}

.popup-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes popupIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.close-button {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 4rem;
  cursor: pointer;
  transition: color 0.3s ease; 
}

.close-button:hover {
  color: #d9534f; 
}
@media (max-width: 850px) {
  .content-slider-container {
    display: block; 
  }
  
  h1 {
    font-size: 30px;
  }

  .slider-nav.left {
    left: 0;
  }

  .slider-nav.right {
    right: 0;
  }

  .popup-content {
    width: 95%;
    height: auto;
  }

  .popup-video {
    height: calc(100vw * (16 / 9));
    width: 100%;
  }

  .carousel-track {
    justify-content: center;
  }

  .item {
    transform: none !important;
    opacity: 1 !important;
  }

  img {
    width: 80%;
    height: auto;
  }
  .text-content h1 ,.text-content p , .text-content h2{
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    width: 100%;
  }
}
</style>
