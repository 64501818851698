<template>
  <div>
    <div v-if="showPopup" class="popup-overlay-ar" @click="closePopup">
      <div class="popup-content-ar" @click.stop>
        <button class="close-button-ar" @click="closePopup">&times;</button>
        <div class="popup-body-ar">
          <div class="popup-text-ar">
            <h2>Register now!</h2>
            <h1>Enjoy the direct installment offer from Monglish</h1>
            <div class="pop-card-ar">
              <p>On cohabitation packages "6 months"</p>
            </div>
            <p class="pop-desc-ar">The lowest price for the level, the highest educational value, and a discount of up to <span>15%</span> when paying in cash</p>
            <form @submit.prevent="register" class="pop-form-ar">
              <div class="inline-group-ar">
                <div class="form-pop-ar">
                  <label for="fullName" class="pop-label-ar">Full Name</label>
                  <input type="text" class="pop-input-ar" id="fullName" placeholder="الاسم الكامل" v-model="fullName" required />
                </div>
                <div class="form-pop-ar">
                  <label for="phoneNumber" class="pop-label-ar">Phone Number</label>
                  <input type="text" class="pop-input-ar" id="phoneNumber" placeholder="رقم الهاتف" v-model="phoneNumber" required />
                </div>
              </div>
                <div class="form-pop-ar">
                <label for="email" class="pop-label-ar">Email</label>
                <input type="email" class="pop-input-ar" id="email" placeholder="example@email.com" v-model="email" required />
              </div>
              <button type="submit" class="pop-btn-ar">Register</button>
            </form>
            <p class="pop-bottom-ar">Personal details are kept by monglish_frontend and will only be used in connection with your application. Please read our <a href="#">terms of use</a> for more information.</p>
          </div>
          <div class="popup-image-ar "></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopup: false,
      fullName: '',
      email: '',
      phoneNumber: ''
    };
  },
  mounted() {
    const popupShown = sessionStorage.getItem('popupShown');
    if (!popupShown) {
      this.showPopup = true;
    }
  },
  methods: {
    closePopup() {
      this.showPopup = false;
      sessionStorage.setItem('popupShown', 'true');
    },
    register() {
      this.closePopup();
    }
  }
};
</script>

<style scoped>
.popup-overlay-ar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  direction: ltr;
  font-family: 'DIN Next LT Arabic-n', sans-serif;
  overflow: auto;
}

.popup-content-ar {
  background: white;
  display: flex;
  max-width: 950px;
  width: 100%;
  max-height:100%;
  border-radius: 20px;
  position: relative;
}

.popup-body-ar {
  display: flex;
  width: 100%;
}

.popup-text-ar {
  flex: 1;
  padding: 30px;
  text-align: start;
}

.popup-text-ar h2 {
  font-size: 25px;
  font-weight: bold;
}

.popup-text-ar h1 {
  font-size: 2.3em;
  font-weight: 700;
  color: #ff6f00;
}

.pop-card-ar {
  background: linear-gradient(45deg, #ff9442, #ff6f00);
  color: white;
  border-radius: 25px 0 0 25px;
  margin-inline-start: -30px;
  padding-inline-start: 30px;
  margin-top: 10px;
  padding-bottom: 5px;
}

.pop-card-ar p {
  font-size: 1.75em; /* adjusted to relative unit */
  font-weight: 500;
}

.pop-desc-ar {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.pop-desc-ar span {
  color: #ff6f00;
}

.popup-image-ar {
  flex: 1;
  background-image: url('@/assets/images/pop.png');
  background-size: cover;
  background-position: center;
  border-radius: 20px 0 0 20px;
  padding: 3.75em; /* adjusted to relative unit */
  width: 100%;
}

.close-button-ar {
  position: absolute;
  top: -1.25rem; /* adjusted to relative unit */
  right: 0.625em; /* adjusted to relative unit */
  background: none;
  border: none;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-size: 3.75em; /* adjusted to relative unit */
  font-weight: 500;
  cursor: pointer;
}

form div {
  margin-bottom: 1.25em; /* adjusted to relative unit */
}

.pop-form-ar {
  text-align: right;
  direction: rtl;
}

.pop-input-ar {
  width: calc(100% - 1.25em); /* adjusted to relative unit */
  padding: 0.625em; /* adjusted to relative unit */
  margin: 0;
  border-radius: 30px;
  background-color: #f3f7ff;
  border: none;
}

form .form-pop-ar {
  margin-bottom: 1.25em; /* adjusted to relative unit */
}

.pop-label-ar {
  display: block;
  margin-right: 0.5em; /* adjusted to relative unit */
  text-align: right;
  font-weight: 500;
  color: #bababa;
  margin-top: -0.625em; /* adjusted to relative unit */
}

.inline-group-ar {
  display: flex;
  justify-content: space-between;
}

.inline-group-ar .form-group-ar {
  width: 48%;
}

.pop-btn-ar {
  background: linear-gradient(45deg, #ff9442, #ff6f00);
  color: white;
  border: none;
  padding: 0.5rem 0.25rem; /* adjusted to relative unit */
  cursor: pointer;
  border-radius: 20px;
  width: 100%;
  font-size: 1.5em; /* adjusted to relative unit */
  font-family: 'DIN Next LT Arabic', sans-serif;
}

.pop-bottom-ar {
  color: #979797;
}


/* Small screens (max-width: 768px) */
@media (max-width: 1000px) {
  .popup-content-ar {
    max-width: 90%;
  }
  .popup-text-ar {
    padding: 1.5em;
  }
  .popup-text-ar h2 {
    font-size: 1.5em;
  }
  .popup-text-ar h1 {
    font-size: 2.25em;
  }

  .pop-card-ar {
    margin-inline-start: -1.5em;
  }
  .pop-card-ar p {
    font-size: 1.25em;
  }
  .pop-desc-ar {
    font-size: 1.25em;
  }
  .popup-image-ar {
    display: none;
  }
  .close-button-ar {
    top: -35px;
    right: 10px;
  }
  .pop-input-ar {
    width: calc(100% - 1em); /* adjust width for smaller screens */
  }
  .pop-btn-ar {
    padding: 0.75em 1em; /* adjust padding for smaller screens */
    font-size: 1.25em; /* adjust font size for smaller screens */
  }
}


</style>
