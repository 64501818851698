<template>
  <header dir="rtl">
    <PopUp />
    <ViewOne @scroll-to-packages="scrollToPackages" />
    <ViewTwo @scroll-to-packages="scrollToPackages"/>
    <VideoSliderOne />
    <HomeReasons @scroll-to-packages="scrollToPackages"/>
    <VideoSliderFour />
    <PackagesAr ref="packagesAr" />
    <VideoSliderTwo />
    <HomeSwiper />
    <HomeCircle />
    <VideoSliderThree/>
    <HomeCertificate />
    <HomeCounter />
    <VideoSliderFive />
    <ViewThree />
  </header>
</template>

<script>
  import ViewOne from '../../components/ar/ViewOne.vue'; 
  import ViewTwo from '../../components/ar/ViewTwo.vue'; 
  import ViewThree from '../../components/ar/ViewThree.vue'; 
  import PackagesAr from '../../views/ar/PackagesPage.vue';
  import HomeCounter from '../../components/ar/HomeCounter.vue';
  import HomeCircle from '../../components/ar/HomeCircle.vue';
  import HomeSwiper from '../../components/ar/HomeSwiper.vue';
  import VideoSliderOne from '../../components/ar/VideoSliderOne.vue';
  import VideoSliderTwo from '../../components/ar/VideoSliderTwo.vue';
  import VideoSliderThree from '../../components/ar/VideoSliderThree.vue';
  import VideoSliderFour from '../../components/ar/VideoSliderFour.vue';
  import VideoSliderFive from '../../components/ar/VideoSliderFive.vue';
  import HomeReasons from '../../components/ar/HomeReasons.vue';
  import PopUp from '../../components/ar/PopUp.vue';
  import HomeCertificate from "@/components/ar/HomeCertificate.vue";

  export default {
    name: 'HomePage',
    components: {
      HomeCertificate,
      ViewOne,
      ViewTwo,
      ViewThree,
      PackagesAr,
      HomeCounter,
      HomeCircle,
      HomeSwiper,
      VideoSliderOne,
      VideoSliderTwo,
      VideoSliderThree,
      VideoSliderFour,
      VideoSliderFive,
      HomeReasons,
      PopUp
    },
    methods: {
      scrollToPackages() {
        this.$refs.packagesAr.$el.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
</script>