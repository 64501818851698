import { createRouter, createWebHistory } from 'vue-router';

// English imports
import HomeEn from '../views/en/HomePage.vue';
import OurTeamEn from '../views/en/OurTeamPage.vue';
import KidsCoursesEn from '../views/en/KidsCoursesPage.vue';
import AdultsCoursesEn from '../views/en/AdultsCoursesPage.vue';
import AboutUsEn from '../views/en/AboutUsPage.vue';
import JoinUsEn from '../views/en/JoinUsPage.vue';
import BlogListEn from '../views/en/BlogList.vue';
import BlogOneEn from '@/components/en/BlogOne.vue';
import BlogTwoEn from '@/components/en/BlogTwo.vue';
import BlogThreeEn from '@/components/en/BlogThree.vue';
import BlogFourEn from '@/components/en/BlogFour.vue';
import BlogFiveEn from '@/components/en/BlogFive.vue';
import BlogSixEn from '@/components/en/BlogSix.vue';
import ContactUsEn from '../views/en/ContactUsPage.vue';
import RegisterEn from '../views/en/RegisterPage.vue';
import LoginEn from '../views/en/LoginPage.vue';
import VisionMissionEn from '../views/en/VisionAndMission.vue';
import StudyAbroadEn from '../views/en/StudyAbroad.vue';
import FAQ_En from '../views/en/FAQ_Page.vue';
import AccredationsEn from '../views/en/AccredationsPage.vue';
import PackagesEn from '../views/en/PackagesPage.vue';
import CartEn from '../views/en/CartPage.vue';
import MonglishImmerseEn from '../views/en/MonglishImmerse.vue';
import MonglishPromiseEn from '../views/en/MonglishPromise.vue';
import MonglishReasonsEn from '../views/en/MonglishReasons.vue';
import CertificationsEn from '../views/en/CertificationsPage.vue';
import OurFounderEn from '../views/en/OurFounder.vue';
import YoutubeVideosEn from '../views/en/YoutubeVideos.vue';
import PaymentSuccessEn from '../views/en/PaymentSuccessPage.vue';
import PhotoGalleryEn from '../views/en/PhotoGallery.vue';
import RefundComponent from '@/components/en/RefundPolicy.vue';
import SecurityComponent from '@/components/en/SecurityPolicy.vue';
import PrivacyPolicyComponent from '@/components/en/PrivacyPolicy.vue';
import TermsConditionComponent from '@/components/en/TermsConditions.vue';
import AllTermsConditionsComponent from '@/components/en/AllTermsConditionsComponent.vue'; 
import TermsAndConditionsEn from '../views/en/TermsAndConditionsEn.vue';

// Arabic imports
import HomeAr from '../views/ar/HomePage.vue';
import OurTeamAr from '../views/ar/OurTeamPage.vue';
import KidsCoursesAr from '../views/ar/KidsCoursesPage.vue';
import AdultsCoursesAr from '../views/ar/AdultsCoursesPage.vue';
import AboutUsAr from '../views/ar/AboutUsPage.vue';
import JoinUsAr from '../views/ar/JoinUsPage.vue';
import BlogListAr from '../views/ar/BlogList.vue';
import BlogOneAr from '@/components/ar/BlogOne.vue';
import BlogTwoAr from '@/components/ar/BlogTwo.vue';
import BlogThreeAr from '@/components/ar/BlogThree.vue';
import BlogFourAr from '@/components/ar/BlogFour.vue';
import BlogFiveAr from '@/components/ar/BlogFive.vue';
import BlogSixAr from '@/components/ar/BlogSix.vue';
import BlogSevenAr from '@/components/ar/BlogSeven.vue';
import BlogEightAr from '@/components/ar/BlogEight.vue';
import BlogNineAr from '@/components/ar/BlogNine.vue';
import BlogTenAr from '@/components/ar/BlogTen.vue';
import BlogElevenAr from '@/components/ar/BlogEleven.vue';
import BlogTwelveAr from '@/components/ar/BlogTwelve.vue';
import BlogThirteenAr from '@/components/ar/BlogThirteen.vue';
import BlogFourteenAr from '@/components/ar/BlogFourteen.vue';
import BlogFifteenAr from '@/components/ar/BlogFifteen.vue';
import BlogSixteenAr from '@/components/ar/BlogSixteen.vue';
import BlogSeventeenAr from '@/components/ar/BlogSeventeen.vue';
import BlogEighteenAr from '@/components/ar/BlogEighteen.vue';
import ContactUsAr from '../views/ar/ContactUsPage.vue';
import RegisterAr from '../views/ar/RegisterPage.vue';
import LoginAr from '../views/ar/LoginPage.vue';
import VisionMissionAr from '../views/ar/VisionAndMission.vue';
import StudyAbroadAr from '../views/ar/StudyAbroad.vue';
import FAQ_Ar from '../views/ar/FAQ_Page.vue';
import AccredationsAr from '../views/ar/AccredationsPage.vue';
import PackagesAr from '../views/ar/PackagesPage.vue';
import CartAr from '../views/ar/CartPage.vue';
import MonglishImmerseAr from '../views/ar/MonglishImmerse.vue';
import MonglishPromiseAr from '../views/ar/MonglishPromise.vue';
import MonglishReasonsAr from '../views/ar/MonglishReasons.vue';
import CertificationsAr from '../views/ar/CertificationsPage.vue';
import OurFounderAr from '../views/ar/OurFounder.vue';
import YoutubeVideosAr from '../views/ar/YoutubeVideos.vue';
import PaymentSuccessAr from '../views/ar/PaymentSuccessPage.vue';
import PhotoGalleryAr from '../views/ar/PhotoGallery.vue';
import TermsAndConditions from '../views/ar/termsandconditions.vue';
import ImmerseClubs from '../components/ar/ImmerseClubs.vue';
import RefundComponentAr from '@/components/ar/RefundPolicy.vue';
import SecurityComponentAr from '@/components/ar/SecurityPolicy.vue';
import PrivacyPolicyComponentAr from '@/components/ar/PrivacyPolicy.vue';
import TermsConditionComponentAr from '@/components/ar/TermsCondition.vue';
import AllTermsConditionsComponentAr from '@/components/ar/AllTermsConditionsComponent.vue'; 

const englishRoutes = [
  { path: '/en/', name: 'HomeEn', component: HomeEn },
  { path: '/en/our-team', name: 'OurTeamEn', component: OurTeamEn },
  { path: '/en/about-us', name: 'AboutUsEn', component: AboutUsEn },
  { path: '/en/join-us', name: 'JoinUsEn', component: JoinUsEn },
  { path: '/en/blog', name: 'BlogsEn', component: BlogListEn },
  { path: '/en/blog/learning-english-in-early-ages', name: 'BlogOneEn', component: BlogOneEn },
  { path: '/en/blog/celebrating-childrens-language-progress', name: 'BlogTwoEn', component: BlogTwoEn },
  { path: '/en/blog/parents-and-children-healthy-relationships/', name: 'BlogFourEn', component: BlogFourEn },
  { path: '/en/blog/seasons-of-the-year', name: 'BlogThreeEn', component: BlogThreeEn },
  { path: '/en/blog/activities-for-positive-behavior', name: 'BlogFiveEn', component: BlogFiveEn },
  { path: '/en/blog/benefits-of-bilingual-for-children', name: 'BlogSixEn', component: BlogSixEn },
  { path: '/en/contact', name: 'ContactUsEn', component: ContactUsEn },
  { path: '/en/register', name: 'RegisterEn', component: RegisterEn },
  { path: '/en/login', name: 'LoginEn', component: LoginEn },
  { path: '/en/vision-mission', name: 'VisionMissionEn', component: VisionMissionEn },
  { path: '/en/study-abroad', name: 'StudyAbroadEn', component: StudyAbroadEn },
  { path: '/en/faq', name: 'FAQ_En', component: FAQ_En },
  { path: '/en/accredations', name: 'AccredationsEn', component: AccredationsEn },
  { path: '/en/packages', name: 'PackagesEn', component: PackagesEn },
  { path: '/en/cart', name: 'CartEn', component: CartEn },
  { path: '/en/kids-courses', name: 'KidsCoursesEn', component: KidsCoursesEn },
  { path: '/en/adult-courses', name: 'AdultsCoursesEn', component: AdultsCoursesEn },
  { path: '/en/monglish-immerse', name: 'MonglishImmerseEn', component: MonglishImmerseEn },
  { path: '/en/monglish-promise', name: 'MonglishPromiseEn', component: MonglishPromiseEn },
  { path: '/en/monglish-reasons', name: 'MonglishReasonsEn', component: MonglishReasonsEn },
  { path: '/en/certifications', name: 'CertificationsEn', component: CertificationsEn },
  { path: '/en/our-founder', name: 'OurFounderEn', component: OurFounderEn },
  { path: '/en/youtube-videos', name: 'YoutubeVideosEn', component: YoutubeVideosEn },
  { path: '/en/photo-gallery', name: 'PhotoGalleryEn', component: PhotoGalleryEn },
  { path: '/en/success', name: 'PaymentSuccessEn', component: PaymentSuccessEn },
 
  {
    path: '/en/terms-and-conditions',
    name: 'TermsAndConditionsEn',
    component: TermsAndConditionsEn,
    children: [
      {
        path: 'all',
        name: 'AllTermsConditionsEn',
        component: AllTermsConditionsComponent,
      },
      {
        path: 'refund',
        name: 'RefundEn',
        component: RefundComponent,
      },
      {
        path: 'security',
        name: 'SecurityEn',
        component: SecurityComponent,
      },
      {
        path: 'privacy',
        name: 'PrivacyPolicyEn',
        component: PrivacyPolicyComponent,
      },
      {
        path: 'terms',
        name: 'TermsConditionsEn',
        component: TermsConditionComponent,
      },
      {
        path: '/en/terms-and-conditions/', // This will default to 'all' if no specific tab is provided in the URL
        redirect: '/en/terms-and-conditions/all',
      }
    ],
  }
  
  // { path: '/en/job-registration', name: 'JobRegistrationEn', component: JobRegistrationEn },
  // { path: '/en/affiliate-registration', name: 'AffiliateFormEn', component: AffiliateFormEn },
 
];

const arabicRoutes = [
  { path: '/', name: 'HomeAr', component: HomeAr },
  { path: '/ar/our-team', name: 'OurTeamAr', component: OurTeamAr },
  { path: '/ar/about-us', name: 'AboutUsAr', component: AboutUsAr },
  { path: '/ar/join-us', name: 'JoinUsAr', component: JoinUsAr },
  { path: '/ar/blog', name: 'BlogsAr', component: BlogListAr },
  { path: '/ar/blog/learning-english-in-early-ages', name: 'BlogOneAr', component: BlogOneAr },
  { path: '/ar/blog/dr-shalaby-on-bentelbalad-program', name: 'BlogTwoAr', component: BlogTwoAr },
  { path: '/ar/blog/edulexa-360-group', name: 'BlogThreeAr', component: BlogThreeAr },
  { path: '/ar/blog/investing-in-monglish-team', name: 'BlogFourAr', component: BlogFourAr },
  { path: '/ar/blog/monthly-celebration-of-the-ideal-employee', name: 'BlogFiveAr', component: BlogFiveAr },
  { path: '/ar/blog/new-achievement-in-dr-najjars-career', name: 'BlogSixAr', component: BlogSixAr },
  { path: '/ar/blog/innovative-education-methods', name: 'BlogSevenAr', component: BlogSevenAr },
  { path: '/ar/blog/leadership-workshops', name: 'BlogEightAr', component: BlogEightAr },
  { path: '/ar/blog/international-partnerships', name: 'BlogNineAr', component: BlogNineAr },
  { path: '/ar/blog/future-of-digital-education', name: 'BlogTenAr', component: BlogTenAr },
  { path: '/ar/blog/students-achievements', name: 'BlogElevenAr', component: BlogElevenAr },
  { path: '/ar/blog/technology-integration', name: 'BlogTwelveAr', component: BlogTwelveAr },
  { path: '/ar/blog/annual-education-conference', name: 'BlogThirteenAr', component: BlogThirteenAr },
  { path: '/ar/blog/teachers-training-program', name: 'BlogFourteenAr', component: BlogFourteenAr },
  { path: '/ar/blog/community-engagement', name: 'BlogFifteenAr', component: BlogFifteenAr },
  { path: '/ar/blog/parent-involvement-importance', name: 'BlogSixteenAr', component: BlogSixteenAr },
  { path: '/ar/blog/scholarship-programs', name: 'BlogSeventeenAr', component: BlogSeventeenAr },
  { path: '/ar/blog/educational-research-and-innovation', name: 'BlogEighteenAr', component: BlogEighteenAr },  
  
  { path: '/ar/contact', name: 'ContactUsAr', component: ContactUsAr },
  { path: '/ar/register', name: 'RegisterAr', component: RegisterAr },
  { path: '/ar/login', name: 'LoginAr', component: LoginAr },
  { path: '/ar/vision-mission', name: 'VisionMissionAr', component: VisionMissionAr },
  { path: '/ar/study-abroad', name: 'StudyAbroadAr', component: StudyAbroadAr },
  { path: '/ar/faq', name: 'FAQ_Ar', component: FAQ_Ar },
  { path: '/ar/accredations', name: 'AccredationsAr', component: AccredationsAr },
  { path: '/ar/packages', name: 'PackagesAr', component: PackagesAr },
  { path: '/ar/cart', name: 'CartAr', component: CartAr },
  { path: '/ar/kids-courses', name: 'KidsCoursesAr', component: KidsCoursesAr },
  { path: '/ar/adult-courses', name: 'AdultsCoursesAr', component: AdultsCoursesAr },
  { path: '/ar/monglish-immerse', name: 'MonglishImmerseAr', component: MonglishImmerseAr },
  { path: '/ar/monglish-promise', name: 'MonglishPromiseAr', component: MonglishPromiseAr },
  { path: '/ar/monglish-reasons', name: 'MonglishReasonsAr', component: MonglishReasonsAr },
  { path: '/ar/certifications', name: 'CertificationsAr', component: CertificationsAr },
  { path: '/ar/our-founder', name: 'OurFounderAr', component: OurFounderAr },
  { path: '/ar/youtube-videos', name: 'YoutubeVideosAr', component: YoutubeVideosAr },
  { path: '/ar/photo-gallery', name: 'PhotoGalleryAr', component: PhotoGalleryAr },
  { path: '/ar/immerse', name: 'ImmerseClubs', component: ImmerseClubs },
  { path: '/ar/success', name: 'PaymentSuccessAr', component: PaymentSuccessAr },
 
  {
    path: '/ar/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
    children: [
      {
        path: 'all',
        name: 'All',
        component: AllTermsConditionsComponentAr,

      },
      {
        path: 'refund',
        name: 'Refund',
        component: RefundComponentAr,
      },
      {
        path: 'security',
        name: 'Security',
        component: SecurityComponentAr,
      },
      {
        path: 'privacy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicyComponentAr,
      },
      {
        path: 'terms',
        name: 'TermsConditions',
        component: TermsConditionComponentAr,

      },
      {
        path: '/ar/terms-and-conditions/', // This will default to 'all' if no specific tab is provided in the URL
        redirect: '/ar/terms-and-conditions/all',
      }
    ],
  }
  
];

const routes = [...englishRoutes, ...arabicRoutes];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
