<template>
    <div class="accre-sec2">
        <div class="video-container">
            <video autoplay muted loop id="background-video">
                <source src="@/assets/images/acc/vid.webm" type="video/mp4">
            </video>
        </div>
        <div class="content-right">
            <h1>تغيُّر المعايير</h1>
            <p>من خلال مناقشاتنا في قطاع التدريب، تبين أن التحديات الحالية قد تكون لها تأثيرات طويلة المدى. الجائحة جلبت تحديات إضافية مثل الأزمات المالية ومخاوف النزاهة والجودة. نحن في المنظمة الدولية لجودة التدريب نعمل على تقديم قيمة ملموسة في هذا القطاع، من خلال خبرتنا في ضمان الجودة وتحسينها باستمرار. مبادئنا ترشدنا في استراتيجيتنا لدعم تأسيس سمعة عالمية متميزة.</p>
            <h6>* المصدر: موقع IOTQ USA.</h6>
        </div>
        <div class="content-left">
            <img src="@/assets/images/acc/sec2.png">
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.accre-sec2 {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    font-family: 'DIN Next LT Arabic';
    font-weight: 500;
    flex-direction: row;
}

.video-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: -1;
}

#background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.content-right {
    width: 60%;
    right: 5%;
    max-width: 50%;
    top: 20%;
    z-index: 1;
    position: relative;
    color: #808080;
}

.content-right h1 {
    font-size: 3em;
    font-family: 'DIN Next LT Arabic-n';
    font-weight: 700;
    margin-bottom: 20px;
    color: #165e84;
}

.content-right p {
    font-size: 1.4rem;
    margin-bottom: 20px;
}

.content-right h6 {
    font-size: 0.8rem;
}

.content-left {
    width: 40%;
    left: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: relative;
    background-color: rgba(62, 130, 164, 0.5);
}

.content-left img {
    max-width: 100%;
}

@media (max-width: 1200px) {
    .content-right, .content-left {
        width: 50%;
    }

    .content-right h1 {
        font-size: 2.5em;
    }

    .content-right p {
        font-size: 1.2rem;
    }

    .content-left {
        background-color: rgba(62, 130, 164, 0.4);
    }
}
@media (max-width: 768px) {
    .accre-sec2 {
        flex-direction: column;
        height: auto;
    }

    .content-right, .content-left {
        width: 100%;
        max-width: 100%;
        text-align: center;
        right: 0;
        left: 0;
    }
    .content-right h6 {
        margin-bottom: 2em;
    }

    .content-left {
        left: 0;
    }
    .content-right h1 {
        font-size: 1.8em;
    }
    .content-right p {
        font-size: 1.1rem;
    }
    .content-left {
        left: 0;
    }
}
</style>
