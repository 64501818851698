<template>
  <header dir="ltr">
    <PopUp />
    <ViewOne @scroll-to-packages="scrollToPackages" />
    <ViewTwo @scroll-to-packages="scrollToPackages" />
    <VideoSliderOne />
    <HomeReasons @scroll-to-packages="scrollToPackages" />
    <VideoSliderFour />
    <PackagesEn ref="PackagesEn" />
    <VideoSliderTwo />
    <HomeSwiper />
    <HomeCircle />
    <VideoSliderThree />
    <HomeCertificate />
    <HomeCounter />
    <VideoSliderFive />
    <ViewThree />
  </header>
</template>


<script>
import ViewOne from '../../components/en/ViewOne.vue';
import ViewTwo from '../../components/en/ViewTwo.vue';
import ViewThree from '../../components/en/ViewThree.vue';
import PackagesEn from './PackagesPage.vue';
import HomeCounter from '../../components/en/HomeCounter.vue';
import HomeCircle from '../../components/en/HomeCircle.vue';
import HomeSwiper from '../../components/en/HomeSwiper.vue';
import VideoSliderOne from '../../components/en/VideoSliderOne.vue';
import VideoSliderTwo from '../../components/en/VideoSliderTwo.vue';
import VideoSliderThree from '../../components/en/VideoSliderThree.vue';
import VideoSliderFour from '../../components/en/VideoSliderFour.vue';
import VideoSliderFive from '../../components/en/VideoSliderFive.vue';
import HomeReasons from '../../components/en/HomeReasons.vue';
import PopUp from '../../components/en/PopUp.vue';
import HomeCertificate from "@/components/en/HomeCertificate.vue";

export default {
  name: 'HomePage',
  components: {
    HomeCertificate,
    ViewOne,
    ViewTwo,
    ViewThree,
    PackagesEn,
    HomeCounter,
    HomeCircle,
    HomeSwiper,
    VideoSliderOne,
    VideoSliderTwo,
    VideoSliderThree,
    VideoSliderFour,
    VideoSliderFive,
    HomeReasons,
    PopUp
  },
  methods: {
    scrollToPackages() {
      this.$refs.PackagesEn.$el.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
</script>